import styled from "styled-components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LuEye } from "react-icons/lu";
import { LuSave } from "react-icons/lu";
import { useExtensionsContext } from "../hooks/useExtensionsContext";
import { useAuthContext } from "../hooks/useAuthContext";

const RightsideInvoice = ({
  onButtonClick,
  checked,
  handleChange,
  onDownloadClick,
}) => {
  const [invoice, setInvoice] = useState("normal");
  const { user } = useAuthContext();
  const { extensions } = useExtensionsContext();

  const isGSTInvoiceExtensionInstalled =
    user &&
    extensions &&
    extensions.some(
      (extension) =>
        extension.extensionName === "GST Invoice" && extension.installed
    );

  const toggleInvoice = () => {
    setInvoice(invoice === "normal" ? "gstInvoice" : "normal");
  };

  return (
    <Container>
      <SideOptions>
        <Title>
          <h2>Properties</h2>
        </Title>
        {isGSTInvoiceExtensionInstalled && (
          <InvoiceSwitchWrapper onClick={toggleInvoice} invoice={invoice}>
            <InvoiceIconWrapper invoice={invoice}></InvoiceIconWrapper>
            <Icon
              selected={invoice === "normal"}
              style={{
                left: "84px",
                top: "calc(50% - 09px)",
                fontFamily: "Open Sans",
                fontSize: "14px",
              }}
            >
              <div className='DDGX'>Normal</div>
            </Icon>
            <Icon
              className='GSTINVLabel'
              selected={invoice === "gstInvoice"}
              style={{
                right: "84px",
                top: "calc(50% - 09px)",
                fontFamily: "Open Sans",
                fontSize: "14px",
              }}
            >
              <div className='DDG'>GST Invoice </div>
            </Icon>
          </InvoiceSwitchWrapper>
        )}

        <AutosaveCheckbox>
          <label>
            <input type='checkbox' checked={checked} onChange={handleChange} />
            Autosave Invoice State
          </label>
        </AutosaveCheckbox>

        <BUTTONSDIVINE>
          <button disabled={checked} onClick={onButtonClick}>
            <LuSave style={{fontSize: "19px", marginRight: "4px"}} />
            Save Draft
          </button>

          <NoName2>
            <Link className='CreateInvoiceButton' to='/invoice'>
              <CreateInvoice
                onClick={onDownloadClick}
                className='CreateInvoiceButton'
              >
                <IconContainer>
                  <LuEye />
                </IconContainer>
                Preview
              </CreateInvoice>
            </Link>
          </NoName2>
        </BUTTONSDIVINE>
        <AlertBox>
          <div className='icon'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v7h2zm0 4h-2v2h2z' />
            </svg>
          </div>
          <div className='content'>
            <h3>Warning</h3>
            <p>This Website is currently under development.</p>
          </div>
        </AlertBox>
        <AlertBox2>
          <div className='icon'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-11h-2v6h2zm0 4h-2v2h2z' />
            </svg>
          </div>
          <div className='content'>
            <h3>Alert</h3>
            <p>Some of the features may not work.</p>
          </div>
        </AlertBox2>
      </SideOptions>
    </Container>
  );
};

const Container = styled.div`
  grid-area: rightside;
  width: 464px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const SideOptions = styled.div`
  text-align: center;
  overflow: hidden;
  margin-bottom: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  position: relative;
  border: none;
  padding: 12px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 9px;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Open Sans";
  line-height: 3;
  font-weight: bolder;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const NoName2 = styled.div`
  margin-top: 10px;
  width: 216px;
  height: 60px;
  justify-content: center;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const CreateInvoice = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 100, 225, 255);
  font-family: "Open Sans";
  height: 50px;
  width: 100%;
  border-radius: 10px;
  vertical-align: middle;
  z-index: 0;
  transition-duration: 167ms;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1366px) {
    width: 80%;
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    width: 80%;
    font-size: 14px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const AlertBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  font-family: "Open Sans";
  @media (max-width: 1366px) {
    width: 89%;
  }

  @media (max-width: 1280px) {
    width: 89%;
  }

  @media (max-width: 768px) {
    width: 88%;
    max-width: 400px;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffd2d2;
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      fill: #f55a5a;
    }
  }

  .content {
    flex: 1;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }
`;

const BUTTONSDIVINE = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > button {
    border: none;
    width: 216px;
    height: 50px;
    border-radius: 8px;
    cursor: pointer;
    margin-right: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    display: flex;

    &:disabled {
      background-color: lightgrey;
      cursor: not-allowed;
      box-shadow: none;
    }
  }
  @media (max-width: 1366px) {
    width: 113%;
    & > button {
      font-size: 14px;
      width: 296px;
    }
  }

  @media (max-width: 1280px) {
    width: 113%;
    & > button {
      font-size: 14px;
      width: 296px;
    }
  }
`;

const AlertBox2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  font-family: "Open Sans";
  @media (max-width: 1366px) {
    width: 89%;
  }

  @media (max-width: 1280px) {
    width: 89%;
  }

  @media (max-width: 768px) {
    width: 88%;
    max-width: 400px;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffeb99;
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      fill: #e6b800;
    }
  }

  .content {
    flex: 1;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }
`;

const InvoiceSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.invoice === "normal" ? "#eef3f8" : "#eef3f8"};
  width: 432px;
  height: 20px;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  position: relative;
  @media (max-width: 1366px) {
    width: 98%;
  }

  @media (max-width: 1280px) {
    width: 98%;
  }
`;

const InvoiceIconWrapper = styled.div`
  position: absolute;
  width: 216px;
  height: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: ${(props) => (props.invoice === "normal" ? "#fff" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  transform: ${(props) =>
    props.invoice === "normal" ? "translateX(0)" : "translateX(216px)"};

  @media (max-width: 1366px) {
    width: 50%;
    height: 80%;
    position: absolute;
    transform: ${(props) =>
      props.invoice === "normal" ? "translateX(0)" : "translateX(174px)"};
  }

  @media (max-width: 1280px) {
    width: 50%;
    height: 80%;
    position: absolute;
    transform: ${(props) =>
      props.invoice === "normal" ? "translateX(0)" : "translateX(164px)"};
  }
`;

const Icon = styled.div`
  position: absolute;
  color: ${(props) => (props.selected ? "black" : "darkgrey")};

  @media (max-width: 1366px) {
    position: absolute;
    width: -100%;
  }

  @media (max-width: 1280px) {
    position: absolute;
    width: -100%;
  }
`;

const AutosaveCheckbox = styled.div`
  display: flex;
  padding-top: 10px;
  @media (max-width: 1366px) {
    align-items: center;
    & label {
      font-size: 14px;
    }
    & > input {
      width: 80%;
      height: 80%;
    }
  }

  @media (max-width: 1280px) {
    align-items: center;
    & label {
      font-size: 14px;
    }
    & > input {
      width: 80%;
      height: 80%;
    }
  }
`;

export default RightsideInvoice;
