import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomerDetails from "./customerDetails";
import CustomerForm from "./customerForm";
import { useCustomersContext } from "../../hooks/useCustomersContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import CancelIcon from "@mui/icons-material/Cancel";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios"; // import axios
import { CgAddR } from "react-icons/cg";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const Customer = () => {
  const { customers, dispatch } = useCustomersContext();
  const { user } = useAuthContext();

  const [isFormVisible, setFormVisible] = useState(false);

  const handleAddCustomer = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/customers`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        dispatch({ type: "SET_CUSTOMERS", payload: response.data });
      } catch (error) {
        // handle your error
        console.error("There was an error!", error);
      }
    };

    if (user) {
      fetchCustomers();
    }
  }, [dispatch, user]);

  const handleCustomerAdded = () => {
    toast.success("Customer Added");
  };

  return (
    <CustomerContainer>
      <HeaderContainer>
        <Title className='aboutUsName'>customers</Title>
        <ButtonContainer>
          <Button onClick={handleAddCustomer}>
            <FlexButtonContainer>
              <CgAddR style={{ fontSize: "22px" }} />

              <ButtonText>Add Customer</ButtonText>
            </FlexButtonContainer>
          </Button>
        </ButtonContainer>
      </HeaderContainer>
      <CustomerDiv>
        {customers && <CustomerDetails customers={customers} />}
      </CustomerDiv>
      {isFormVisible && (
        <Backdrop>
          <Popup>
            <CancelIcon
              onClick={handleCloseForm}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                fontSize: "large",
                color: "darkgrey",
              }}
            />
            <CustomerForm
              onClose={handleCloseForm}
              onCustomerAdded={handleCustomerAdded}
            />
          </Popup>
        </Backdrop>
      )}
      <Toaster position='top-center' />
    </CustomerContainer>
  );
};

const CustomerContainer = styled.div`
  width: 824px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 704px;
  @media (max-width: 1366px) {
    width: 100%;
    height: 582px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    height: 552px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  padding: 0 14px;
  margin-bottom: 20px;
  @media (max-width: 1366px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }

  @media (max-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }
`;

const Title = styled.h1``;

const ButtonContainer = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  @media (max-width: 1280px) {
  }
`;

const CustomerDiv = styled.div`
  /* border: 1px solid; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-color: lightgray;
  border-radius: 4px;
  margin: 10px;
  @media (max-width: 1366px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
  }

  @media (max-width: 1280px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 160px;
  background-color: rgba(0, 100, 225, 255);
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 1366px) {
    width: 150px;
  }
  @media (max-width: 1280px) {
    width: 150px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Popup = styled.div`
  position: relative;
  width: 400px;
  padding: 20px;
  justify-content: right;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 1000;
`;

const FlexButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  justify-content: center;
`;

const ButtonText = styled.span`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default Customer;
