import { SaleEntriesContext } from "../context/saleEntryContext";
import { useContext } from "react";

export const useSaleEntriesContext = () => {
  const context = useContext(SaleEntriesContext);
  if (!context) {
    throw Error(
      "useInvoiceContext must be used inside an InvoicesContextProvider"
    );
  }
  return context;
};