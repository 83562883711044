import React, { useState } from "react";
import styled from "styled-components";
import { useRequestsContext } from "../../hooks/useRequestsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Toaster } from "react-hot-toast";
import axios from "axios"; // import axios
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const Request = () => {
  const { dispatchRequest } = useRequestsContext();
  const { user } = useAuthContext();
  const [requestName, setRequestName] = useState("");
  const [description, setDescription] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    const request = {
      requestName,
      description,
      fullName,
      email,
    };

    try {
      const response = await axios.post(`${API_URL}/api/requests`, request, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      // Reset the form and show success message
      setRequestName("");
      setDescription("");
      setFullName("");
      setEmail("");
      setError(null);
      setEmptyFields([]);
      dispatchRequest({ type: "CREATE_REQUEST", payload: response.data });
      toast.success("Feature request sent");
    } catch (error) {
      if (error.response && error.response.data) {
        // This is a server validation error
        setError(error.response.data.error);
        setEmptyFields(error.response.data.emptyFields || []); // <-- Here
        toast.error(error.response.data.error);
      } else {
        // This is a network error or other unexpected error
        setError(error.message);
        setEmptyFields([]);
        toast.error(error.message);
      }
    }
  };

  const isFormValid = requestName && description;

  return (
    <RequestContainer>
      <HeaderContainer>
        <Title className='aboutUsName'>request a feature</Title>
      </HeaderContainer>
      <RequestDiv>
        <FeatureDiv className='Request_feature_form'>
          <FormContainer onSubmit={handleSubmit}>
            <h3 className='ACCF'>Missing a feature?</h3>
            <p>
              Anything you're missing in our product? Drop a request here to let
              us know
            </p>

            <FormGroup>
              <label>Which feature are you missing?</label>
              <input
                type='text'
                onChange={(e) => setRequestName(e.target.value)}
                value={requestName}
                autoFocus
                className={emptyFields.includes("productName") ? "error" : ""}
              />
            </FormGroup>

            <FormGroup>
              <label>What problem would that solve for you?</label>
              <input
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                className={emptyFields.includes("size") ? "error" : ""}
              />
            </FormGroup>

            <FormGroup>
              <label>Full Name (optional)</label>
              <input
                type='text'
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />
            </FormGroup>
            <FormGroup>
              <label>Email (optional) </label>
              <input
                type='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormGroup>
            <ButtonContainer>
              <button disabled={!isFormValid} onClick={handleSubmit}>
                Send Request
              </button>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </ButtonContainer>
          </FormContainer>
        </FeatureDiv>
      </RequestDiv>

      <Toaster position='top-center' />
    </RequestContainer>
  );
};

const RequestContainer = styled.div`
  width: 824px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 704px;
  @media (max-width: 1366px) {
    width: 100%;
    height: 562px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    height: 542px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  padding: 0 14px;
  margin-bottom: 20px;
  @media (max-width: 1366px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }
  @media (max-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }
`;

const Title = styled.h1``;

const RequestDiv = styled.div`
  /* border: 1px solid; */
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  border-color: lightgray;
  border-radius: 16px;
  margin: 10px;
  @media (max-width: 1366px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
    justify-content: center;
  }
  @media (max-width: 1280px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
  }
`;

const FeatureDiv = styled.div`
  position: relative;
  top: -20px;

  @media (max-width: 1366px) {
    position: relative;
    top: 0px;
    left: 0px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
  max-width: 682px;
  padding: 55px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 10px 30px -5px rgba(73, 93, 207, 0.2);

  & h3 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Open Sans";
  }

  & p {
    font-size: 12px;
    font-weight: 400;
    font-family: "Open Sans";
    padding-bottom: 20px;
    color: darkgray;
  }

  @media (max-width: 1366px) {
    width: 586px;
    height: 402px;
    margin-top: 10px;
    padding: 10px;
    box-shadow: none;
    justify-content: center;

    & h3 {
      font-size: 18px;
      font-weight: 600;
      font-family: "Open Sans";
    }

    & p {
      font-size: 10px;
      font-weight: 400;
      font-family: "Open Sans";
      padding-bottom: 10px;
      color: darkgray;
    }
  }
  @media (max-width: 1280px) {
    width: 532px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-family: "Open Sans";

  label {
    color: rgba(0, 100, 255, 255);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  & input {
    border: none;
    padding: 14px 16px;
    font-size: 14px;
    border-radius: 12px;
    background: #f6f8fb;
    font-family: "Open Sans";

    &:focus {
      /* outline: 2px solid rgba(0, 100, 255, 255); */
      outline: 2px solid rgba(0, 100, 255, 255);
      box-shadow: 0px 10px 30px -5px rgba(73, 93, 207, 0.2);
    }

    &.error {
      border: 2px solid red;
    }
  }

  @media (max-width: 1366px) {
    margin-bottom: 10px;

    label {
      color: rgba(0, 100, 255, 255);
      /* color: black; */
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    & input {
      border: none;
      padding: 12px 14px;
      font-size: 12px;
      border-radius: 12px;
      background: #f6f8fb;
      font-family: "Open Sans";

      & textarea {
        width: 100%;
        height: 12px; // adjust this as needed
        padding: 14px 16px;
        font-size: 16px;
        border-radius: 12px;
        border: none;
        background: #f6f8fb;
        resize: vertical; // allows the user to resize the textarea vertically

        &:focus {
          outline: 2px solid rgba(0, 100, 255, 255);
          box-shadow: 0px 10px 30px -5px rgba(73, 93, 207, 0.2);
        }

        &.error {
          border: 2px solid red;
        }
      }
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    font-size: 16px;
    padding: 14px 36px;
    color: #fff;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background: linear-gradient(90deg, rgba(0, 100, 255, 255) 0%, #a17fe0 100%);
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }

    &:disabled {
      background: #ddd;
      cursor: not-allowed;
    }
  }
`;

const ErrorMessage = styled.div`
  font-size: 16px;
  color: red;
  margin-top: 10px;
`;

export default Request;
