import React, { useMemo } from "react";
import styled from "styled-components";
import { useExtensionsContext } from "../../hooks/useExtensionsContext";
import { useAuthContext } from "../../hooks/useAuthContext";

const ProfileSwSwitch = ({ selected, setSelected }) => {
  // const [selected, setSelected] = useState("edit");
  const { user } = useAuthContext();
  const { extensions } = useExtensionsContext();

  const handleClick = (option) => {
    setSelected(option);
  };

  const isGSTInvoiceExtensionInstalled =
    user &&
    extensions &&
    extensions.some(
      (extension) =>
        extension.extensionName === "GST Invoice" && extension.installed
    );

  // Calculate the width of the ThemeSwitchWrapper
  const wrapperWidth = useMemo(
    () => (isGSTInvoiceExtensionInstalled ? 340 : 192),
    [isGSTInvoiceExtensionInstalled]
  );

  return (
    <ThemeSwitchWrapper width={wrapperWidth}>
      <ThemeIconWrapper
        selected={selected}
        isGSTInstalled={isGSTInvoiceExtensionInstalled}
      ></ThemeIconWrapper>
      <Icon
        selected={selected === "edit"}
        onClick={() => handleClick("edit")}
        style={{
          left: "16px",
          top: "calc(50% - 9.1px)",
          fontFamily: "Open Sans",
          fontSize: "13px",
        }}
      >
        Edit Profile
      </Icon>
      {isGSTInvoiceExtensionInstalled && (
        <Icon
          selected={selected === "info"}
          onClick={() => handleClick("info")}
          style={{
            left: "98px",
            top: "calc(50% - 9.1px)",
            fontFamily: "Open Sans",
            fontSize: "13px",
          }}
        >
          Company Information
        </Icon>
      )}
      <Icon
        selected={selected === "plan"}
        onClick={() => handleClick("plan")}
        style={{
          right: isGSTInvoiceExtensionInstalled ? "12px" : "12px",
          top: "calc(50% - 9.1px)",
          fontFamily: "Open Sans",
          fontSize: "13px",
        }}
      >
        Plan & Pricings
      </Icon>
    </ThemeSwitchWrapper>
  );
};

const ThemeSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eef3f8;
  width: ${(props) => `${props.width}px`};
  height: 20px;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  position: relative;
`;

const ThemeIconWrapper = styled.div`
  position: absolute;
  width: ${(props) =>
    props.selected === "edit"
      ? "88px"
      : props.selected === "info" && props.isGSTInstalled
      ? "148px"
      : "106px"};
  height: 24px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  left: ${(props) =>
    props.selected === "edit"
      ? "4px"
      : props.selected === "info" && props.isGSTInstalled
      ? "90px"
      : props.selected === "plan"
      ? props.isGSTInstalled
        ? "240px"
        : "92px"
      : "4px"};
  transition: left 0.3s ease, width 0.3s ease;
`;

const Icon = styled.div`
  position: absolute;
  color: ${(props) => (props.selected ? "black" : "darkgrey")};
`;

export default ProfileSwSwitch;
