import React from "react";
import DarkModeExtension from "../../extensions/Dark mode/darkmodeExtension";
import styled from "styled-components";
import GSTInvoiceExtension from "../../extensions/GST_Invoice/GSTinvoice";

const ExtensionsDetails = ({ handleInstall, handleUninstall, extensions }) => {
  const darkModeExtension = extensions && extensions.find(ext => ext.extensionName === "Dark Mode");
  const gSTInvoiceExtension = extensions && extensions.find(ext => ext.extensionName === "GST Invoice");
  

  return (
    <ExtensionDetailsContainer>
      <ExtensionCards>
        <DarkModeExtension 
          onInstall={handleInstall} 
          onUninstall={handleUninstall} 
          isInstalled={!!darkModeExtension && darkModeExtension.installed}
          extensionId={darkModeExtension ? darkModeExtension._id : null}
        />
      </ExtensionCards>
      <ExtensionCards>
        <GSTInvoiceExtension 
          onInstall={handleInstall} 
          onUninstall={handleUninstall} 
          isInstalled={!!gSTInvoiceExtension && gSTInvoiceExtension.installed}
          extensionId={gSTInvoiceExtension ? gSTInvoiceExtension._id : null}
        />
      </ExtensionCards>
    </ExtensionDetailsContainer>
  );
};

const ExtensionDetailsContainer = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  overflow: auto;
`;

const ExtensionCards = styled.div`
  width: 200px;
  height: 260px;
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-style: "Open Sans";
`;

export default ExtensionsDetails;
