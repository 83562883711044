import React, { useState } from "react";
import styled from "styled-components";

const SaleFilterSwitch = ({ selected, setSelected }) => {
  const [saleswitch, setSaleSwitch] = useState("allrecords");

  // const toggleSaleSwitch = () => {
  //   setSaleSwitch(saleswitch === "allrecords" ? "bycompany" : "allrecords");
  // };

  const toggleSaleSwitch = () => {
    const newSwitchState =
      saleswitch === "allrecords" ? "bycompany" : "allrecords";
    setSaleSwitch(newSwitchState);
    setSelected(newSwitchState);
  };

  const handleClick = (option) => {
    setSelected(option);
    setSaleSwitch(option);
  };

  return (
    <SaleSwitchSwitchWrapper onClick={toggleSaleSwitch} saleswitch={saleswitch}>
      {/* <SaleSwitchIconWrapper
        selected={selected}
        saleswitch={saleswitch}
        onClick={toggleSaleSwitch}
      ></SaleSwitchIconWrapper> */}
      <SaleSwitchIconWrapper
        selected={selected}
        saleswitch={saleswitch}
        onClick={toggleSaleSwitch}
      ></SaleSwitchIconWrapper>

      <Icon
        selected={saleswitch === "allrecords"}
        onClick={(e) => {
          e.stopPropagation();
          handleClick("allrecords");
        }}
        style={{
          left: "calc(50% - 96px)",
          top: "calc(50% - 10px)",
          fontSize: "14px",
          fontFamily: "Open Sans",
        }}
      >
        All Records
      </Icon>
      <Icon
        selected={saleswitch === "bycompany"}
        onClick={(e) => {
          e.stopPropagation();
          handleClick("bycompany");
        }}
        style={{
          right: "calc(50% - 102px)",
          top: "calc(50% - 10px)",
          fontSize: "14px",
          fontFamily: "Open Sans",
        }}
      >
        By Company
      </Icon>
    </SaleSwitchSwitchWrapper>
  );
};

const SaleSwitchSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) =>
    props.saleswitch === "allrecords" ? "#eef3f8" : "#eef3f8"};
  width: 240px;
  height: 16px;
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  position: relative;
`;

// const SaleSwitchIconWrapper = styled.div`
//   position: absolute;
//   width: 120px;
//   height: 20px;
//   border-radius: 4px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
//   background: ${(props) =>
//     props.saleswitch === "allrecords" ? "#fff" : "#fff"};
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   transition: all 0.5s ease;
//   transform: ${(props) =>
//     props.saleswitch === "allrecords" ? "translateX(0)" : "translateX(120px)"};
// `;

const SaleSwitchIconWrapper = styled.div`
  position: absolute;
  width: 120px;
  height: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: ${(props) =>
    props.saleswitch === "allrecords" ? "#fff" : "#fff"};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  transform: ${(props) =>
    props.saleswitch === "allrecords" ? "translateX(0)" : "translateX(120px)"};
  cursor: pointer;
`;

const Icon = styled.div`
  position: absolute;
  color: ${(props) => (props.selected ? "black" : "darkgrey")};
`;

export default SaleFilterSwitch;
