import React from "react";
import "./Main.css";
import styled from "styled-components";
import SalesGraph from "./SalesGraph";
// import CustomerGraph from "./CustomerGraph";

const MainDetails = () => {
  return (
    <>
      <div>
        <h1 className='aboutUsName'>dashboard</h1>
      </div>
      <MainDetailsDiv>
        <SalesGraphDiv>
          <SalesGraph />
        </SalesGraphDiv>
        <TotalCustomerDiv>
          {/* <CustomerGraph /> */}
        </TotalCustomerDiv>
      </MainDetailsDiv>
    </>
  );
};

const MainDetailsDiv = styled.div`
  display: flex;
  padding: 20px;
  column-gap: 20px;
  flex: 1;
`;

const SalesGraphDiv = styled.div`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalCustomerDiv = styled.div`
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default MainDetails;
