import React from "react";
import styled from "styled-components";
import VerifiedIcon from "@mui/icons-material/Verified";
import Zoom from "@mui/material/Zoom";
import { Link } from "react-router-dom";

const PasswordChangeSuccess = () => {
  return (
    <Container>
      <SSC>
        <ICDiv>
          <Zoom in={true} style={{ transitionDelay: "500ms" }}>
            <VerifiedIcon style={{ fontSize: "x-large" }} />
          </Zoom>
        </ICDiv>
        <PXCV>
          Password reset success!
          <br />
        </PXCV>
        <h2>Welcome to AccountLoop! </h2>
        <PXCV>
          Good news! Your password has been successfully reset. For security
          reasons, ensure you keep your login information confidential. If you
          notice any suspicious activity or did not request this reset, please
          contact our support team right away. Thank you for choosing our
          services.
        </PXCV>
        <PXCV>
          Best regards, <br />
          Rishav Chhabra <br />
          AccountLoop Team
        </PXCV>
        <Link className='GOTOLOGIN' to='/login'>
          <Ddiv>Go to Login</Ddiv>
        </Link>
      </SSC>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(241, 248, 255, 0.8);
  backdrop-filter: blur(10px);
`;

const ICDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const SSC = styled.div`
  background-color: rgba(0, 100, 225, 255);
  border: none;
  width: 360px;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  justify-content: center;
  text-align: left;
  font-family: "Open Sans";
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);

  /* Centering styles */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & h2 {
    padding-left: 10px;
    font-size: 18px;
    font-weight: 600;
    font-family: "Open Sans";
  }
`;

const PXCV = styled.p`
  font-size: 14px;
  padding: 10px;
`;

const Ddiv = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;

  &:hover {
    cursor: pointer;
    background-color: white;
    color: black;
    border: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
`;

export default PasswordChangeSuccess;
