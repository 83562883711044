import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SaleDetails from "./saleDetails";
import SaleForm from "./saleForm";
import { useInvoicesContext } from "../../hooks/useInvoicesContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import CancelIcon from "@mui/icons-material/Cancel";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios"; // import axios
import { CgAddR } from "react-icons/cg";
import { useSaleEntriesContext } from "../../hooks/useSaleEntriesContext";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const Sale = () => {
  const { invoices, invoicedispatch } = useInvoicesContext();
  const { saleentries, saleEntrydispatch } = useSaleEntriesContext();
  const { user } = useAuthContext();

  const [isFormVisible, setFormVisible] = useState(false);

  const handleAddSale = () => {
    setFormVisible(true);
  };

  const handleCloseForm = () => {
    setFormVisible(false);
  };

  ///////////////////////////////////////////////////////////

  useEffect(() => {
    let isCancelled = false;
  
    const fetchSales = async () => {
      if (!isCancelled) {
        try {
          const response = await axios.get(`${API_URL}/api/sales`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          invoicedispatch({ type: "SET_INVOICES", payload: response.data });
        } catch (error) {
          // handle your error
          console.error("There was an error!", error);
        } finally {
          if (!isCancelled) {
            setTimeout(fetchSales, 5000);
          }
        }
      }
    };
  
    if (user) {
      fetchSales();
    }
  
    return () => {
      isCancelled = true;
    };
  }, [invoicedispatch, user]);
  



  //////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchSaleEntry = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/saleEntry`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        saleEntrydispatch({ type: "SET_SALEENTRIES", payload: response.data });
      } catch (error) {
        // handle your error
        console.error("There was an error!", error);
      }
    };

    if (user) {
      fetchSaleEntry();
      const interval = setInterval(fetchSaleEntry, 5000); // Now it will run every 5 seconds
      return () => clearInterval(interval);
    }
  }, [saleEntrydispatch, user]);

  const handleSaleAdded = () => {
    toast.success("Sale Added");
  };

  return (
    <SaleContainer>
      <HeaderContainer>
        <Title className='aboutUsName'>sales</Title>
        <ButtonContainer>
          <Button onClick={handleAddSale}>
            <FlexButtonContainer>
              <CgAddR style={{ fontSize: "22px" }} />

              {/* <AddIcon /> */}
              <ButtonText>Add Entry</ButtonText>
            </FlexButtonContainer>
          </Button>
        </ButtonContainer>
      </HeaderContainer>
      <SaleDiv>
        {invoices && saleentries && (
          <SaleDetails sales={invoices} saleEntry={saleentries} />
        )}
      </SaleDiv>
      {isFormVisible && (
        <Backdrop>
          <Popup>
            <CancelIcon
              onClick={handleCloseForm}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                fontSize: "large",
                color: "darkgrey",
              }}
            />
            {invoices && saleentries && (
              <SaleForm
                sales={invoices}
                saleEntry={saleentries}
                onClose={handleCloseForm}
                onSaleAdded={handleSaleAdded}
              />
            )}
          </Popup>
        </Backdrop>
      )}
      <Toaster position='top-center' />
    </SaleContainer>
  );
};

const SaleContainer = styled.div`
  width: 824px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 784px;
  @media (max-width: 1366px) {
    width: 100%;
    height: 682px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    height: 632px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 14px;
  padding: 0 14px;
  margin-bottom: 20px;
  @media (max-width: 1366px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }
  @media (max-width: 1280px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7px;
    padding: 0 7px;
    margin-bottom: 10px;
  }
`;

const Title = styled.h1``;

const ButtonContainer = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  @media (max-width: 1280px) {
  }
`;

const SaleDiv = styled.div`
  /* border: 1px solid; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-color: lightgray;
  border-radius: 4px;
  margin: 10px;
  @media (max-width: 1366px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
  }
  @media (max-width: 1280px) {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-color: lightgray;
    border-radius: 4px;
    margin: 8px;
  }
`;

const Button = styled.button`
  border: none;
  cursor: pointer;
  border-radius: 8px;
  width: 160px;
  background-color: rgba(0, 100, 225, 255);
  color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 1366px) {
    width: 150px;
  }
  @media (max-width: 1280px) {
    width: 150px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Popup = styled.div`
  position: relative;
  width: 400px;
  padding: 20px;
  justify-content: right;
  background: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  z-index: 1000;
`;

const FlexButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  justify-content: center;
`;

const ButtonText = styled.span`
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default Sale;
