import { useState } from "react";
import axios from "axios";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;


export const useSignup = () => {
  const [error, setError] = useState(null);
  const [isloading, setIsloading] = useState(null);

  const signup = async (
    firstName,
    lastName,
    company,
    phoneNumber,
    address,
    email,
    password
  ) => {
    setIsloading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_URL}/api/users/signup`, {
        firstName,
        lastName,
        company,
        phoneNumber,
        address,
        email,
        password,
      });

      if(response.status === 200 && response.data) {
        setIsloading(false);
        return true; // Return true indicating signup was successful
      } else {
        throw new Error("Unexpected server response");
      }
    } catch (error) {
      setIsloading(false);
      setError(error.response.data.error); // error.message is changed to error.response.data.error to get error message from backend
      return false; // Return false indicating signup failed
    }
  };

  return { signup, isloading, error }; // return these so they can be used outside the hook
};
