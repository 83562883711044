import { InvoicesContext } from "../context/invoiceContext";
import { useContext } from "react";

export const useInvoicesContext = () => {
  const context = useContext(InvoicesContext);
  if (!context) {
    throw Error(
      "useInvoiceContext must be used inside an InvoicesContextProvider"
    );
  }
  return context;
};