import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useProfile } from "../../hooks/useProfile";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import "./profile.css";
import ProfileSwSwitch from "./profileSwitch";
import DangerZone from "./dangerZone";
import PlanAndpricing from "./plan&pricing";

const Profile = () => {
  const { user } = useAuthContext();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { profile, error, isLoading } = useProfile();
  const [selected, setSelected] = useState("edit");

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setCompany(user.company);
      setPhoneNumber(user.phoneNumber);
      setAddress(user.address);
      setEmail(user.email);
    }
  }, [user]);

  const handleUpdateProfile = async () => {
    await profile(
      firstName,
      lastName,
      company,
      phoneNumber,
      address,
      email,
      password
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmation(true);
  };

  const handleConfirmUpdate = () => {
    handleUpdateProfile();
    setShowConfirmation(false);
  };

  const handleCancelUpdate = () => {
    setShowConfirmation(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <EditProfileContainer>
      <div className='TPSCSSS'>
        <h1 className='aboutUsName'>settings</h1>
        <ProfileSwSwitch selected={selected} setSelected={setSelected} />
      </div>
      {selected === "edit" && (
        <EDITPROFILEFORM>
          <ProfileForm onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor='firstName'>First Name</Label>
              <Input
                type='text'
                id='firstName'
                name='firstName'
                className='EditProfileInputs'
                autoComplete='given-name'
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='lastName'>Last Name</Label>
              <Input
                type='text'
                id='lastName'
                name='lastName'
                className='EditProfileInputs'
                autoComplete='family-name'
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='company'>Company</Label>
              <Input
                type='text'
                id='company'
                name='company'
                className='EditProfileInputs'
                autoComplete='organization'
                onChange={(e) => setCompany(e.target.value)}
                value={company}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='phoneNumber'>Phone Number</Label>
              <Input
                type='number'
                id='phoneNumber'
                name='phoneNumber'
                className='EditProfileInputs'
                autoComplete='tel'
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='address'>Address</Label>
              <Input
                type='text'
                id='address'
                name='address'
                className='EditProfileInputs'
                autoComplete='street-address'
                onChange={(e) => setAddress(e.target.value)}
                value={address}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='email'>Email</Label>
              <Input
                type='email'
                id='email'
                name='email'
                className='EditProfileInputs'
                autoComplete='email'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='password'>Password</Label>
              <InputWrapper>
                <Input
                  id='password'
                  name='password'
                  className='EditProfileInputsX'
                  type={showPassword ? "text" : "password"}
                  autoComplete='new-password'
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
                <TogglePasswordVisibility onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <VisibilityOff className='tswitch' />
                  ) : (
                    <Visibility className='tswitch' />
                  )}
                </TogglePasswordVisibility>
              </InputWrapper>
            </FormGroup>

            <div className='ButtonsEditProfile'>
              <Link to='/home'>
                <button className='ButtonsEP'>Cancel</button>
              </Link>
              <ButtonSubmit
                className='ButtonsEP'
                type='submit'
                disabled={isLoading}
              >
                Update
              </ButtonSubmit>
            </div>

            {error && <div>{error}</div>}
          </ProfileForm>
          <div>
            <DangerZone />
          </div>
          {showConfirmation && (
            <ConfirmationPopup>
              <div className='popup-content'>
                <h2>Confirmation</h2>
                <p>Are you sure you want to update your profile?</p>
                <div className='button-container'>
                  <ButtonCancel className='NCFCSS' onClick={handleCancelUpdate}>
                    Cancel
                  </ButtonCancel>
                  <ButtonConfirm
                    className='YCFCSS'
                    onClick={handleConfirmUpdate}
                  >
                    Yes
                  </ButtonConfirm>
                </div>
              </div>
            </ConfirmationPopup>
          )}
        </EDITPROFILEFORM>
      )}
      {selected === "plan" && <PlanAndpricing />}
    </EditProfileContainer>
  );
};

const EditProfileContainer = styled.div`
  grid-area: main;
  max-width: 824px;
  height: 704px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  @media (max-width: 1366px) {
    width: 90%;
    height: 532px;
  }
  @media (max-width: 1280px) {
    width: 90%;
    height: 532px;
  }
`;

const EDITPROFILEFORM = styled.div``;

const ProfileForm = styled.form`
  border-top: 1px solid;
  border-color: lightgray;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
  margin-top: 14px;
  padding: 0 14px;
  margin-bottom: 20px;
  padding-top: 10px;
  @media (max-width: 1366px) {
    border-top: 1px solid;
    border-color: lightgray;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    margin-top: 14px;
    padding: 0 7px;
    margin-bottom: 10px;
    padding-top: 10px;
  }
  @media (max-width: 1280px) {
    border-top: 1px solid;
    border-color: lightgray;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: left;
    margin-top: 14px;
    padding: 0 7px;
    margin-bottom: 10px;
    padding-top: 10px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  @media (max-width: 1366px) {
    margin-bottom: 0.5rem;
  }
  @media (max-width: 1280px) {
    margin-bottom: 0.5rem;
  }
`;

const Label = styled.label`
  width: 120px;
  margin-right: 0.5rem;
  font-size: 14px;
  color: darkgray;
  /* font-weight: 600; */
  font-size: "Open Sans";
  @media (max-width: 1366px) {
    width: 100px;
    margin-right: 0.25rem;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 100px;
    margin-right: 0.25rem;
    font-size: 12px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  @media (max-width: 1366px) {
    width: 100px;
    position: relative;
    left: -1px;
    &>input{
      width: 93%;
    }
  }
  @media (max-width: 1280px) {
    width: 100px;
    position: relative;
    left: -4px;
    &>input{
      width: 394px;
    }
  }
`;

const Input = styled.input`
  width: 412px;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: lightgray;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Open Sans";
  outline: none;
`;

const TogglePasswordVisibility = styled.div`
  position: absolute;
  right: 188px;
  top: 54%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (max-width: 1366px) {
    position: absolute;
    right: 22px;
  }
  @media (max-width: 1280px) {
    position: absolute;
    right: 10px;
  }
`;

const ButtonSubmit = styled.button`
  padding: 0.5rem 1rem;
  font-size: 14px;
  /* background-color: white; */
  background-color: rgba(0, 100, 225, 255);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  cursor: pointer;
  @media (max-width: 1280px) {
  }
`;

const ConfirmationPopup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 999;
`;

const ButtonConfirm = styled.button``;

const ButtonCancel = styled.button``;

export default Profile;
