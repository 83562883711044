import styled from "styled-components";

import MainDetails from "./MainDetails";

const Main = (props) => {
  return (
    <Container>
      <LayoutMain>
        <MainDetails />
      </LayoutMain>
    </Container>
  );
};

const Container = styled.div`
  grid-area: main;
  width: 824px;
  height: 704px;
  border-radius: 8px;
  @media (max-width: 1366px) {
    width: 100%;
    height: 532px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    height: 532px;
  }
`;

const LayoutMain = styled.div`
  display: flex;
  column-gap: 25px;
  background-color: white;
  flex-direction: column;
  padding: 14px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  row-gap: 25px;
  grid-template-rows: auto;
  grid-template-columns: auto;
  margin: 0;
  @media (max-width: 1366px) {
    display: flex;
    column-gap: 12.5px;
    background-color: white;
    flex-direction: column;
    padding: 7px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    row-gap: 12.5px;
    grid-template-rows: auto;
    grid-template-columns: auto;
    margin: 0;
  }
  @media (max-width: 1280px) {
    display: flex;
    column-gap: 12.5px;
    background-color: white;
    flex-direction: column;
    padding: 7px;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    row-gap: 12.5px;
    grid-template-rows: auto;
    grid-template-columns: auto;
    margin: 0;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
`;

export default Main;
