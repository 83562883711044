import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;


export const useProfile = () => {
  const [error, setError] = useState(null);
  const [isloading, setIsloading] = useState(null);
  const { user, dispatch } = useAuthContext();

  const profile = async (
    firstName,
    lastName,
    company,
    phoneNumber,
    address,
    email,
    password
  ) => {
    setIsloading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_URL}/api/users/profile`, {
        firstName,
        lastName,
        company,
        phoneNumber,
        address,
        email,
        password,
      }, {
        headers: { 
          "Authorization": `Bearer ${user.token}`
        },
      });

      //Save the user to local storage
      localStorage.setItem("user", JSON.stringify(response.data));

      //Update the AuthContext
      dispatch({ type: "LOGIN", payload: response.data });

      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      setError(error.message);
    }
  };

  return { profile, isloading, error };
};

