import React, { useEffect } from "react";
import axios from "axios";
import ExtensionsDetails from "./extensionsDetails";
import { useExtensionsContext } from "../../hooks/useExtensionsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast from "react-hot-toast";
import styled from "styled-components";
import "./extensions.css";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const Extensions = () => {
  const { extensions, dispatch } = useExtensionsContext();
  const { user } = useAuthContext();

  useEffect(() => {
    const fetchExtensions = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/extensions`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        dispatch({ type: "SET_EXTENSIONS", payload: response.data });
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    if (user) {
      fetchExtensions();
    }
  }, [dispatch, user]);

  const handleInstall = (extension) => {
    extension.installed = true; // Set installed to true

    const installPromise = axios
      .post(`${API_URL}/api/extensions`, extension, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`, // if authentication is required
        },
      })
      .then((response) => {
        // Handle response
        console.log(response.data);
        dispatch({ type: "CREATE_EXTENSION", payload: response.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    toast.promise(installPromise, {
      loading: "Installing...",
      success: "Installation successful",
      error: "Installation failed",
    });
  };

  const handleUninstall = async (extensionId) => {
    try {
      await axios.delete(`${API_URL}/api/extensions/${extensionId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      dispatch({ type: "DELETE_EXTENSION", payload: extensionId });
      toast.success("Extension uninstalled");
    } catch (error) {
      toast.error("Error: Action failed!");
    }
  };

  return (
    <ExtensionsContainer>
      <EXPCONT>
        <Title className='aboutUsName'>extensions</Title>
        <div>
          <ExtensionCards>
            <ExtensionsDetails
              handleInstall={handleInstall}
              handleUninstall={handleUninstall}
              extensions={extensions}
            />
          </ExtensionCards>
        </div>
      </EXPCONT>
    </ExtensionsContainer>
  );
};

const ExtensionsContainer = styled.div`
  grid-area: main;
  width: 824px;
  height: 704px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow: auto;
  @media (max-width: 1366px) {
    width: 100%;
    height: 532px;
  }
  @media (max-width: 1280px) {
    width: 100%;
    height: 532px;
  }
`;

const EXPCONT = styled.div`
  padding: 14px;

  & h1 {
    font-family: "Caveat", cursive;
    font-size: 48px;
  }
`;

const Title = styled.div``;

const ExtensionCards = styled.div`
  display: flex;
  flex-direction: row;
`;

export default Extensions;
