import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import axios from "axios";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;


export const useLogin = () => {
  const [error, setError] = useState(null);
  const [isloading, setIsloading] = useState(false);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setIsloading(true);
    setError(null);
    let data = null;

    try {
      const response = await axios.post(`${API_URL}/api/users/login`, {
        email,
        password
      });

      // Save the user to local storage
      localStorage.setItem("user", JSON.stringify(response.data));

      // Update the AuthContext
      dispatch({ type: "LOGIN", payload: response.data });

      // Assign response data to the variable 'data'
      data = response.data;
      
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      setError(error.response.data.error); // error.message is changed to error.response.data.error to get error message from backend
    }

    return data; // return the response data
  };

  return { login, isloading, error }; // return these so they can be used outside the hook
};

