import { createContext, useReducer, useEffect } from "react";

export const ExtensionsContext = createContext();

export const extensionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_EXTENSIONS":
      return {
        extensions: action.payload,
      };
    case "CREATE_EXTENSION":
      return {
        extensions: [action.payload, ...state.extensions],
      };
    case "DELETE_EXTENSION":
      return {
        extensions: state.extensions.filter(
          (ext) => ext._id !== action.payload
        ),
      };
    case "INSTALL_EXTENSION":
      return {
        extensions: state.extensions.map((ext) =>
          ext._id === action.payload._id ? { ...ext, installed: true } : ext
        ),
      };
    default:
      return state;
  }
};

export const ExtensionsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(extensionsReducer, {
    extensions: JSON.parse(localStorage.getItem("extensions")) || [],
  });

  useEffect(() => {
    localStorage.setItem("extensions", JSON.stringify(state.extensions));
  }, [state.extensions]);

  return (
    <ExtensionsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ExtensionsContext.Provider>
  );
};
