import { VendorsContext } from "../context/vendorContext";
import { useContext } from "react";

export const useVendorsContext = () => {
  const context = useContext(VendorsContext);
  if (!context) {
    throw Error(
      "useVendorContext must be used inside an VendorsContextProvider"
    );
  }
  return context;
};