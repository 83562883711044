import React from "react";
import { useState, useEffect } from "react";
import { useSignup } from "../../hooks/useSignup";
import styled from "styled-components";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import "./signup.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Signup = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, isloading } = useSignup();
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const navigate = useNavigate();


  //logic for Error

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {}, 1000); // Function passed to setTimeout is empty

      return () => {
        clearTimeout(timer);
      };
    }
  }, [error]);

  //logic for terms and conditions

  const handleTermsCheckboxChange = (e) => {
    setShowTermsPopup(e.target.checked);

    const isChecked = e.target.checked;
    setAgreeToTerms(isChecked);
  };

  //-------------------------------

  //logic for checking password

  const conditions = [
    { regex: /[a-z]/, message: "Include a lowercase letter" },
    { regex: /[A-Z]/, message: "Include an uppercase letter" },
    { regex: /[0-9]/, message: "Include a number" },
    { regex: /[\W_]/, message: "Include a special character" },
    { regex: /.{8,}/, message: "Be at least 8 characters long" },
  ];

  const checkPassword = () => {
    for (const condition of conditions) {
      const isConditionMet = condition.regex.test(password);
      const conditionElement = document.getElementById(condition.regex.source);

      if (isConditionMet) {
        conditionElement.classList.remove("invalid");
        conditionElement.classList.add("valid");
      } else {
        conditionElement.classList.remove("valid");
        conditionElement.classList.add("invalid");
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const success = await signup(
      firstName,
      lastName,
      company,
      phoneNumber,
      address,
      email,
      password
    );

    if (success) {
      navigate('/Success');
    }
  };



  return (
    <SignupForm className='signup-card'>
      <form className='FormCSS' onSubmit={handleSubmit}>
        <h3 className='signupHeader'>Sign up for accountloop ID</h3>
        <div className='from-row'>
          <label className='nameLabelsTop'>First Name:</label>
          <input
            className='inputLabelsTop'
            type='text'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
          />
          {/* </div>
        <div className='from-row'> */}
          <label className='nameLabelsTop'>Last Name:</label>
          <input
            className='inputLabelsTop'
            type='text'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </div>

        <label className='nameLabels'>Company:</label>
        <input
          className='inputLabels'
          type='text'
          onChange={(e) => setCompany(e.target.value)}
          value={company}
        />

        <label className='nameLabels'>Phone Number:</label>
        <input
          className='inputLabels'
          type='number'
          minLength='10'
          onChange={(e) => setPhoneNumber(e.target.value)}
          value={phoneNumber}
        />

        <label className='nameLabels'>Address:</label>
        <input
          className='inputLabels'
          type='text'
          onChange={(e) => setAddress(e.target.value)}
          value={address}
        />
        <label className='nameLabels'>Email:</label>
        <input
          className='inputLabels'
          type='email'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <label className='nameLabels'>Password:</label>
        <input
          className='inputLabels'
          type='password'
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          onInput={checkPassword}
        />

        <div className='PasswordCondition'>
          {conditions.map((condition) => (
            <div
              key={condition.regex.source}
              id={condition.regex.source}
              className={`condition${
                password && condition.regex.test(password) ? " valid" : ""
              }`}
            >
              {password && condition.regex.test(password) && (
                <CheckCircleIcon className='tick' />
              )}
              {condition.message}
            </div>
          ))}
        </div>
        <div>
          <label className='TermsConditionsCheckbox' htmlFor='termsCheckbox'>
            <input
              type='checkbox'
              id='termsCheckbox'
              checked={agreeToTerms}
              onChange={handleTermsCheckboxChange}
            />
            <label>Please agree to terms and conditions</label>
          </label>
        </div>

        <div>
          {showTermsPopup && (
            <TermsPopup className='TermsBox'>
              <h1 className='TCheading'>Terms and Conditions</h1>
              <p className='TCtext'>
                Before signing up, you must agree to the following terms and
                conditions.
              </p>
              <TermsText>
                <div className='TCtext'>
                  <p className='TCheading'>1. AccountLoop Service</p>
                  <p>
                    {" "}
                    1.1 The Service provided by AccountLoop allows Users to
                    manage their business accounts, track transactions, and
                    access financial tools and resources.
                  </p>
                  <p>
                    {" "}
                    1.2 AccountLoop reserves the right to modify, suspend, or
                    terminate the Service at any time without prior notice.
                  </p>

                  <p className='TCheading'>2. User Responsibilities</p>
                  <p>
                    2.1 You are responsible for maintaining the confidentiality
                    of your AccountLoop account credentials, including your
                    username and password. You must not share your account
                    credentials with any third parties.
                  </p>
                  <p>
                    2.2 You agree to provide accurate and up-to-date information
                    when using the Service and to promptly update any changes to
                    your account information.
                  </p>
                  <p>
                    2.3 You are solely responsible for any actions taken through
                    your AccountLoop account. You will notify AccountLoop
                    immediately of any unauthorized use or suspected security
                    breach of your account.
                  </p>

                  <p className='TCheading'>3. Privacy and Data Security</p>
                  <p>
                    3.1 AccountLoop respects your privacy and takes data
                    security seriously. Please refer to our Privacy Policy for
                    information on how we collect, use, and protect your
                    personal data.
                  </p>
                  <p>
                    3.2 By using the Service, you acknowledge and agree that
                    AccountLoop may collect, use, and disclose your data in
                    accordance with our Privacy Policy.
                  </p>

                  <p className='TCheading'>4. Intellectual Property</p>
                  <p>
                    4.1 All intellectual property rights, including copyrights,
                    trademarks, and patents, related to the Service and its
                    content are owned by AccountLoop or its licensors.
                  </p>
                  <p>
                    4.2 You are granted a limited, non-exclusive,
                    non-transferable license to use the Service for business
                    purposes only. You may not modify, reproduce, distribute, or
                    create derivative works based on the Service without prior
                    written consent from AccountLoop.
                  </p>

                  <p className='TCheading'>5. Limitation of Liability</p>
                  <p>
                    5.1 AccountLoop shall not be liable for any direct,
                    indirect, incidental, consequential, or exemplary damages
                    arising out of or in connection with the use of the Service.
                  </p>
                  <p>
                    5.2 AccountLoop does not warrant the accuracy, reliability,
                    or completeness of any information provided through the
                    Service, and you acknowledge that any reliance on such
                    information is at your own risk.
                  </p>
                  <p>
                    5.3 You agree to indemnify and hold AccountLoop harmless
                    from any claims, damages, liabilities, or expenses arising
                    out of or in connection with your use of the Service or any
                    violation of these Terms.
                  </p>

                  <p className='TCheading'>6. Modifications to the Terms</p>
                  <p>
                    6.1 AccountLoop reserves the right to modify or update these
                    Terms at any time. Any changes will be effective immediately
                    upon posting the revised Terms on the AccountLoop website.
                  </p>
                  <p>
                    6.2 It is your responsibility to review the Terms
                    periodically. Your continued use of the Service after any
                    modifications constitutes acceptance of the updated Terms.
                  </p>

                  <p>
                    Please read these Terms carefully before using AccountLoop.
                    If you have any questions or concerns regarding these Terms,
                    please contact us at{" "}
                    <span className='Semail'>support@accountloop.com</span>
                  </p>
                </div>
              </TermsText>
              <div className="TermsButtonsCss">
                <TermsPopupButton
                  className='DisagreeButton'
                  onClick={() =>
                    handleTermsCheckboxChange({ target: { checked: false } })
                  }
                >
                  Disagree
                </TermsPopupButton>
                <TermsPopupButton
                  className='AgreeButton'
                  onClick={() => setShowTermsPopup(false)}
                >
                  Agree
                </TermsPopupButton>
              </div>
            </TermsPopup>
          )}
        </div>

        <button
          className='CreateAccountButton'
          disabled={isloading || !agreeToTerms}
        >
          Create Account
        </button>
        <p className='signinlink'>
          Already a Member ?{" "}
          <Link className='LoginLink' to='/login'>
            {" "}
            Log in
          </Link>
        </p>

        {error && (
          <div className='error'>
            <ErrorIcon className='errorIcon' />
            <span className='errorText'>{error}</span>
          </div>
        )}
      </form>
    </SignupForm>
  );
};

const SignupForm = styled.div`
  padding-top: 150px;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
`;

const TermsPopup = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-height: 80vh;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  padding: 20px;
  overflow-y: auto;
`;

const TermsText = styled.div`
  overflow-y: auto;
  max-height: 60vh;
  background-color: lightgray;
  border-radius: 4px;
  padding: 8px;
`;

const TermsPopupButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  padding-right: 8px;
  width: 80px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default Signup;
