import React, { useState } from "react";
import styled from "styled-components";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

const ThemeSwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => (props.theme === "light" ? "#eef3f8" : "#eef3f8")};
  width: 100px;
  height: 29.5px;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  position: relative;
`;

const ThemeIconWrapper = styled.div`
  position: absolute;
  width: 51px;
  height: 35px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  background: ${(props) => (props.theme === "light" ? "#fff" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  transform: ${(props) =>
    props.theme === "light" ? "translateX(0)" : "translateX(50px)"};
`;

const Icon = styled.div`
  position: absolute;
  color: ${(props) => (props.selected ? "black" : "darkgrey")};
`;

const DarkModeSwitch = () => {
  const [theme, setTheme] = useState("light");

  const toggleTheme = () => {
    setTheme(theme === "light" ? "dark" : "light");
  };

  return (
    <ThemeSwitchWrapper onClick={toggleTheme} theme={theme}>
      <ThemeIconWrapper theme={theme}></ThemeIconWrapper>
      <Icon 
        selected={theme === "light"} 
        style={{left: 'calc(50% - 34px)', top: 'calc(50% - 09px)'}}
      >
        <LightModeIcon style={{ fontSize: 'large'}}/>
      </Icon>
      <Icon 
        selected={theme === "dark"} 
        style={{right: 'calc(50% - 34px)', top: 'calc(50% - 09px)'}}
      >
        <DarkModeIcon style={{ fontSize: 'large'}} />
      </Icon>
    </ThemeSwitchWrapper>
  );
};

export default DarkModeSwitch;
