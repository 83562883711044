import { CustomersContext } from "../context/customerContext";
import { useContext } from "react";

export const useCustomersContext = () => {
  const context = useContext(CustomersContext);
  if (!context) {
    throw Error(
      "useCustomerContext must be used inside an CustomersContextProvider"
    );
  }
  return context;
};

