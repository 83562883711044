import React from "react";
import styled from "styled-components";

const PlanAndpricing = () => {
  return <Plandiv>This feature is coming soon</Plandiv>;
};

const Plandiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 14px;
  border-color: lightgrey;
`;

export default PlanAndpricing;
