import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { LuTrash } from "react-icons/lu";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import axios from "axios";
import { useProductsContext } from "../../hooks/useProductsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast, { Toaster } from "react-hot-toast";
import "./product.css";
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineFilterAlt } from "react-icons/md";
import { TbArrowsSort } from "react-icons/tb";
import format from "date-fns/format";
import { MdOutlineFileDownload } from "react-icons/md";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const ProductDetails = ({ products }) => {
  const { dispatchproduct } = useProductsContext();
  const { user } = useAuthContext();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [sortOption, setSortOption] = useState("AtoZ"); // Default option is A-Z
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const XLSX = require("xlsx");

  // Sort options
  const sortOptions = [
    { value: "AtoZ", label: "Name A-Z" },
    { value: "ZtoA", label: "Name Z-A" },
    { value: "Latest", label: "Latest First" },
    { value: "Oldest", label: "Oldest First" },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let result = products;

    // Apply date range filter if dates are selected
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      result = result.filter((product) => {
        const productDate = new Date(product.createdAt);
        return productDate >= start && productDate <= end;
      });
    }

    // Apply search term filter
    const lowerCasedFilter = searchTerm.toLowerCase();
    result = result.filter((product) =>
      `${product.productName}`.toLowerCase().includes(lowerCasedFilter)
    );

    // Apply different sort conditions based on selected option
    switch (sortOption) {
      case "AtoZ":
        result = result.sort((a, b) =>
          a.productName.localeCompare(b.productName)
        );
        break;
      case "ZtoA":
        result = result.sort((a, b) =>
          b.productName.localeCompare(a.productName)
        );
        break;
      case "Latest":
        result = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      case "Oldest":
        result = result.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        break;
      default:
        break;
    }

    const productsWithSNo = result.map((product, index) => ({
      ...product,
      sNo: index + 1,
    }));
    setFilteredProducts(productsWithSNo);
  }, [searchTerm, sortOption, products, startDate, endDate]);

  //////////////////////////////////////

  //////////////////////////////////////

  const handleClick = async () => {
    if (!user || !selectedProduct) {
      return;
    }

    try {
      await axios.delete(`${API_URL}/api/products/${selectedProduct._id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      dispatchproduct({ type: "DELETE_PRODUCT", payload: selectedProduct._id });
      toast.success("Product deleted");
    } catch (error) {
      toast.error("Error: Action failed!");
    }

    setIsDeletePopupOpen(false);
    setSelectedProduct(null);
  };

  const confirmDelete = (product) => {
    setSelectedProduct(product);
    setIsDeletePopupOpen(true);
  };

  const cancelDelete = () => {
    setIsDeletePopupOpen(false);
    setSelectedProduct(null);
  };

  const columns = [
    {
      name: "S.no",
      cell: (row) => <div style={{ width: "" }}>{row.sNo}</div>, // Use a fixed width div
      sortable: true,
      grow: 0,
    },
    {
      name: "Name",
      selector: (row) => `${row.productName}`,
    },
    {
      name: "Size",
      selector: (row) => row.size,
    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Created At",
      selector: (row) =>
        formatDistanceToNow(new Date(row.createdAt), { addSuffix: true }),
    },
    {
      name: "Actions",
      cell: (row) => (
        <LuTrash
          style={{ fontSize: "18px", cursor: "pointer" }}
          onClick={() => confirmDelete(row)}
        />
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: windowWidth <= 1280 ? "0.8em" : "1em", // change the font size of header
      },
    },
    cells: {
      style: {
        fontSize: windowWidth <= 1280 ? "0.8em" : "1em", // change the font size of cells
      },
    },
    columns: {
      style: {
        paddingLeft: "8px", // Align text in cells to the left
        paddingRight: "8px",
      },
    },
  };

  const downloadDataAsExcelFile = () => {
    // Map the products array to include only the fields we care about
    const productsToDownload = products.map(
      ({ productName, size, price, createdAt }, index) => ({
        "S.No": index + 1,
        "Product Name": productName,
        Size: size,
        Price: price,
        Date: format(new Date(createdAt), "dd-MM-yyyy"),
      })
    );

    // Create a new worksheet from the products
    let worksheet = XLSX.utils.json_to_sheet(productsToDownload);

    // Create a new workbook, and add our worksheet to it
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Products");

    // Download the workbook
    XLSX.writeFile(workbook, "products.xlsx");
  };

  return (
    <ProductDetail>
      <Toaster />
      <SearchFunctionality>
        All Records
        <SortBy>
          <Button onClick={downloadDataAsExcelFile}>
            <MdOutlineFileDownload style={{ fontSize: "18px" }} />
            Export
          </Button>
          <SortByFunction>
            <SortByTxt>
              <TbArrowsSort style={{ fontSize: "16px" }} />
              Sort :
            </SortByTxt>
            <Select
              styles={CustomStyles}
              options={sortOptions}
              defaultValue={sortOptions[0]}
              isSearchable={false}
              onChange={(option) => setSortOption(option.value)}
            />
          </SortByFunction>
          <div>
            <FilterButton onClick={() => setShowDatePicker(!showDatePicker)}>
              <MdOutlineFilterAlt style={{ fontSize: "18px" }} />
              Filter
            </FilterButton>
            {showDatePicker && (
              <CustomDatePickerWrapper>
                <ResetButton
                  onClick={() => {
                    setStartDate(null);
                    setEndDate(null);
                  }}
                >
                  Reset
                </ResetButton>
                <DatePicker.RangePicker
                  value={[startDate, endDate]}
                  onChange={(dates) => {
                    setStartDate(dates ? dates[0] : null);
                    setEndDate(dates ? dates[1] : null);
                  }}
                  format='YYYY/MM/DD'
                />
              </CustomDatePickerWrapper>
            )}
          </div>
          <input
            type='text'
            placeholder='Search products'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </SortBy>
      </SearchFunctionality>

      <DataTable
        title='Products'
        columns={columns}
        data={filteredProducts}
        noHeader
        dense
        customStyles={customStyles}
        pagination // enable pagination
        paginationPerPage={10} // 10 rows per page
      />

      {isDeletePopupOpen && (
        <div className='popup'>
          <div className='popup-content'>
            <p>Are you sure you want to delete this product?</p>
            <div className='button-container'>
              <button className='NCFCSS' onClick={cancelDelete}>
                No
              </button>
              <button className='YCFCSS' onClick={handleClick}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </ProductDetail>
  );
};

const ProductDetail = styled.div`
  padding: 1rem;

  @media (max-width: 1366px) {
    padding: 0.5rem;
  }

  @media (max-width: 1280px) {
    padding: 0.5rem;
  }
`;

const SearchFunctionality = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  font-size: 14px;
  font-family: "Open Sans";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);

  @media (max-width: 1366px) {
    padding: 5px;
    font-size: 12px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }

  @media (max-width: 1280px) {
    padding: 5px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }
`;

const CustomStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderRadius: "8px",
    borderColor: "lightgrey",
    backgroundColor: "white",
    padding: "0px",
    fontSize: "12px",
    fontWeight: "500",
    width: "80px",
    height: "8px",
    color: "black",
    fontFamily: "Open Sans",
    outline: state.isFocused ? "none" : provided.outline,
    boxShadow: state.isFocused ? "none" : provided.boxShadow, // Add this line
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    borderColor: "#E0E7EE",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "1px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "black" : "#3B3F45",
    backgroundColor: state.isSelected
      ? "darkgrey"
      : state.isFocused
      ? "lightgrey"
      : "white",
    padding: "8px",
    fontSize: "12px",
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3B3F45",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const SortBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;

  & > input {
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    width: 180px;
  }

  @media (max-width: 1366px) {
    font-size: 12px;
    column-gap: 4px;
    & > input {
      padding: 0.7rem;
      border-radius: 0.5rem;
      border: 1px solid lightgray;
      width: 130px;
    }
  }
`;

const FilterButton = styled.button`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e4e7eb;
  }
`;

const CustomDatePickerWrapper = styled.div`
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 6px;
`;

const ResetButton = styled.button`
  background-color: rgba(0, 100, 255, 255);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background-color: #e4e7eb;
  }
`;

const SortByTxt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  font-size: 12px;
`;

const SortByFunction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid lightgrey;
  padding-left: 8px;
  padding-right: 8px;
`;

const Button = styled.button`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 6px;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding-inline: 10px;
  padding-block: 10px;
  font-family: "Open Sans";
  cursor: pointer;
`;

export default ProductDetails;
