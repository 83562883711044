import React, { useState, useRef, useEffect, useCallback } from "react";
import "./invoiceForm.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useReactToPrint } from "react-to-print";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useCustomersContext } from "../../hooks/useCustomersContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useProductsContext } from "../../hooks/useProductsContext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { LuPrinter } from "react-icons/lu";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import toast from "react-hot-toast";
import { useInvoicesContext } from "../../hooks/useInvoicesContext";
import {
  PageContainer,
  TopFlexContainer,
  InvoiceFormContainer,
  InputContainer,
  BilledToContainer,
  StyledEditIcon,
  FromContainer,
  DateContainer,
  Label,
  Input,
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
  IconContainer,
  HorizontalLine,
  GrandTotalSection,
  GrandTotalLabel,
  GrandTotalValue,
  ButtonsContainer,
  AddItemButton,
  ButtonsContainer2,
  PrintButtonMain,
} from "./invoiceStyles";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const InvoiceForm = ({
  shouldAutoRunFunction,
  shouldRunFunction,
  resetRun,
  shouldPrintRunFunction,
}) => {
  //logic for Billed to section
  const quantityRefs = useRef([]);
  const priceRefs = useRef([]);
  const productRef = useRef({});
  const productNameRefs = useRef([]);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);
  const [selectedProductIndex, setSelectedProductIndex] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const { customers, dispatch } = useCustomersContext();
  const { user } = useAuthContext();
  const { products, dispatchProduct } = useProductsContext();
  const componentRef = useRef();
  const { invoicedispatch } = useInvoicesContext();
  const [billedTo, setBilledTo] = useState({ company: "", address: "" });
  const [date, setDate] = useState(new Date());
  const [isEditing, setIsEditing] = useState(true);

  const [items, setItems] = useState([
    { productName: "", quantity: "", price: 0 },
  ]);

  const [invoiceNumber, setInvoiceNumber] = useState("");

  useEffect(() => {
    const fetchLastInvoiceNumber = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/sales`, {
          headers: { Authorization: `Bearer ${user.token}` },
        });
        const sales = response.data;

        // Sort the sales (invoices) by invoice number and get the last sale (invoice)
        const lastInvoice = sales
          .sort(
            (a, b) =>
              parseInt(a.invoiceNumber.replace("IN", ""), 10) -
              parseInt(b.invoiceNumber.replace("IN", ""), 10)
          )
          .slice(-1)[0];

        // Extract the invoice number, increment it, and set it
        if (lastInvoice) {
          const lastInvoiceNumber = parseInt(
            lastInvoice.invoiceNumber.replace("IN", ""),
            10
          );
          const newInvoiceNumber = (lastInvoiceNumber + 1).toString();
          setInvoiceNumber("IN" + newInvoiceNumber.padStart(5, "0"));
        } else {
          // If there are no invoices, initialize it to 1
          setInvoiceNumber("IN00001");
        }
      } catch (error) {
        console.error("There was an error!", error);
      }
    };

    if (user) {
      fetchLastInvoiceNumber();
    }
  }, [user]);

  //////////////////////////////////////////

  useEffect(() => {
    const fetchCustomers = async () => {
      const response = await axios.get(`${API_URL}/api/customers`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        dispatch({ type: "SET_CUSTOMERS", payload: response.data });
      }
    };

    if (user) {
      fetchCustomers();
    }
  }, [dispatch, user]);

  //logic for the product section

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(`${API_URL}/api/products`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        dispatchProduct({ type: "SET_PRODUCTS", payload: response.data });
      }
    };
    if (user) {
      fetchProducts();
    }
  }, [dispatchProduct, user]);

  useEffect(() => {
    const savedBilledTo = JSON.parse(localStorage.getItem("billedTo"));
    const savedItems = JSON.parse(localStorage.getItem("items"));
    const savedIsEditing = JSON.parse(localStorage.getItem("isEditing"));

    if (savedBilledTo) setBilledTo(savedBilledTo);
    if (savedItems) setItems(savedItems);
    if (savedIsEditing !== null) setIsEditing(savedIsEditing);
  }, []);

  const handleInvoiceStateSave = useCallback(() => {
    localStorage.setItem("billedTo", JSON.stringify(billedTo));
    localStorage.setItem("items", JSON.stringify(items));
    localStorage.setItem("isEditing", JSON.stringify(isEditing));
    toast.success("Invoice state saved");
    // console.log("button clicked");
  }, [billedTo, items, isEditing]); // add isEditing to dependencies

  const autoSave = useCallback(() => {
    localStorage.setItem("billedTo", JSON.stringify(billedTo));
    localStorage.setItem("items", JSON.stringify(items));
    localStorage.setItem("isEditing", JSON.stringify(isEditing));
    // console.log("Auto-save executed");
  }, [billedTo, items, isEditing]);

  useEffect(() => {
    if (shouldRunFunction) {
      handleInvoiceStateSave();
      resetRun();
    }
  }, [shouldRunFunction, resetRun, handleInvoiceStateSave]);

  useEffect(() => {
    if (shouldAutoRunFunction) {
      autoSave();
    }
  }, [shouldAutoRunFunction, billedTo, items, isEditing, autoSave]);

  //=================================//

  const handleBilledToChange = (event) => {
    setBilledTo({ ...billedTo, company: event.target.value });

    const searchCustomer = event.target.value.toLowerCase();
    const filtered = customers.filter((customer) => {
      const fullName =
        `${customer.firstName} ${customer.lastName}`.toLowerCase();
      return fullName.startsWith(searchCustomer) && fullName !== searchCustomer;
    });

    setFilteredCustomers(filtered);
    setSelectedCustomerIndex(0);
  };

  const handleOnClick = (customer) => {
    onSearch(`${customer.company} ${customer.address}`);
    setBilledTo({
      id: customer._id,
      company: customer.company,
      address: customer.address,
    });
    setIsEditing(false);
    if (productRef.current) {
      productRef.current?.focus();
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleProductChange = (index, e) => {
    const inputValue = e.target.value;
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      productName: inputValue,
    };
    setItems(updatedItems);

    // Filter the products
    const searchProduct = e.target.value.toLowerCase();
    const filtered = products.filter((product) =>
      product.productName.toLowerCase().startsWith(searchProduct)
    );
    setFilteredProducts(filtered);
    setSelectedProductIndex(0); // Highlight the first result
  };

  const onSearch = (searchCustomer) => {
    handleBilledToChange({ target: { value: searchCustomer } });
  };

  const onSearchProduct = (searchProduct, index) => {
    const selectedProduct = products.find(
      (product) =>
        product.productName.toLowerCase() === searchProduct.toLowerCase()
    );

    if (selectedProduct) {
      const updatedItems = [...items];
      updatedItems[index] = {
        ...updatedItems[index],
        id: selectedProduct._id,
        productName: selectedProduct.productName,
        size: selectedProduct.size,
        price: selectedProduct.price,
      };
      setItems(updatedItems);
      quantityRefs.current[index].focus();
    } else {
      const updatedItems = [...items];
      updatedItems[index].productName = "";
      setItems(updatedItems);
    }
  };

  const handleItemChange = (index, field, searchProduct) => {
    const updatedItems = [...items];
    updatedItems[index][field] = searchProduct;
    setItems(updatedItems);
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Enter" && priceRefs.current[index]) {
      priceRefs.current[index].focus();
      e.preventDefault(); // prevent the default action of the enter key
    }
  };

  const handleAddItem = () => {
    const newItem = { productName: "", quantity: "", price: "" };
    const updatedItems = [...items, newItem];
    setItems(updatedItems);

    setItems(updatedItems);
  };

  const handleRemoveItem = (index) => {
    if (items.length === 1) return; // Prevent removing the last item

    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  const handlePriceKeyDown = (event, index) => {
    if (event.key === "Enter") {
      handleAddItem();
      requestAnimationFrame(() => {
        const nextProductRef = productNameRefs.current[index + 1];
        if (nextProductRef) {
          nextProductRef.focus();
        } else {
          console.error("Element does not exist");
        }
      });
    }
  };

  const grandTotal = items.reduce(
    (total, item) => total + item.quantity * item.price,
    0
  );

  const handleClear = () => {
    setBilledTo({ company: "", address: "" });
    setItems([{ productName: "", size: "", quantity: "", price: "" }]);
    setIsEditing(true); // Assuming "isEditing" is your state variable
    localStorage.removeItem("billedTo");
    localStorage.removeItem("items");
    localStorage.removeItem("isEditing");

    toast.success("All fields cleared.", {
      style: {
        padding: "16px",
        color: "black",
      },
      iconTheme: {
        primary: "rgba(0, 100, 255, 255)",
        secondary: "white",
      },
    });
  };

  //----------------------------------------//
  const handleSubmitInvoice = async () => {
    // Gather all the necessary data into an object
    const invoiceData = {
      customer: billedTo.id, // get the selected customer's id
      invoiceNumber: invoiceNumber, // assuming invoiceNumber is a state variable
      products: items.map((item) => {
        return {
          product: item.id, // get the selected product's id
          quantity: item.quantity,
        };
      }),
    };

    try {
      const response = await axios.post(`${API_URL}/api/sales`, invoiceData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      // Check the response status and handle it appropriately
      if (response.status >= 200 && response.status < 300) {
        toast.success("Invoice submitted successfully");
        invoicedispatch({ type: "CREATE_INVOICE", payload: response.data });
      } else {
        toast.error("An error occurred while submitting the invoice");
      }
    } catch (error) {
      toast.error(`An error occurred: ${error.message}`);
      console.log({ error });
    }
  };

  //------------------------------------------//

  //-----------------------------------------//

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: async () => {
      setTimeout(async () => {
        await handleSubmitInvoice();
        // After the invoice is printed, increment the invoice number
        const newInvoiceNumber = (
          parseInt(invoiceNumber.replace("IN", ""), 10) + 1
        ).toString();
        const paddedInvoiceNumber = "IN" + newInvoiceNumber.padStart(5, "0");
        // Update the invoiceNumber state with the new invoice number
        setInvoiceNumber(paddedInvoiceNumber);
        // Update the last invoice number on the server
      }, 0);
    },
  });

  /////////////////////////////////////////////////////////

  const handlePreview = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (shouldPrintRunFunction) {
      handlePreview();
    }
  }, [handlePreview, shouldPrintRunFunction]);

  return (
    <PageContainer id='invoiceFormContainer' className='IFCP'>
      <InvoiceFormContainer ref={componentRef}>
        <TopFlexContainer>
          <div className='InvoiceNumber'>
            <Label>Invoice:</Label>
            <span className='InvoiceNumberR'> #{invoiceNumber}</span>{" "}
            {/* Here you display the invoice number */}
          </div>

          <DateContainer>
            <Label className='DateLabel' htmlFor='date'>
              Date:
            </Label>
            <DatePicker
              id='date'
              className='DateInput'
              selected={date}
              onChange={(selectedDate) => setDate(selectedDate)}
            />
          </DateContainer>
        </TopFlexContainer>

        <InputContainer>
          <BilledToContainer>
            <Label htmlForm='billedTo'>Billed To:</Label>
            {isEditing ? (
              <Input
                type='text'
                id='billedTo'
                className='BilledToInput'
                value={billedTo.company || ""}
                onChange={handleBilledToChange}
                autoFocus
                onKeyDown={(e) => {
                  if (
                    e.key === "Enter" &&
                    filteredCustomers[selectedCustomerIndex]
                  ) {
                    e.preventDefault(); // Prevent form submission
                    handleOnClick(filteredCustomers[selectedCustomerIndex]);
                    productRef.current?.focus();
                  } else if (e.key === "ArrowDown") {
                    // Move selection down
                    e.preventDefault(); // Prevent cursor from moving in input
                    setSelectedCustomerIndex((prevIndex) =>
                      prevIndex < filteredCustomers.length - 1
                        ? prevIndex + 1
                        : prevIndex
                    );
                  } else if (e.key === "ArrowUp") {
                    // Move selection up
                    e.preventDefault(); // Prevent cursor from moving in input
                    setSelectedCustomerIndex((prevIndex) =>
                      prevIndex > 0 ? prevIndex - 1 : prevIndex
                    );
                  }
                }}
              />
            ) : (
              <div
                className='FinalBilledToSection'
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>
                  <h4 className='BTIF'>{billedTo.company}</h4>
                  <p className='FromAddressLabel'>{billedTo.address}</p>
                </div>

                <StyledEditIcon className='actions' onClick={handleEditClick}>
                  <EditIcon
                    style={{
                      color: "darkgrey",
                      cursor: "pointer",
                      fontSize: "medium",
                    }}
                  />
                </StyledEditIcon>
              </div>
            )}
            <button
              className='BillButton'
              onClick={() => onSearch(billedTo.company)}
            >
              Search
            </button>

            {filteredCustomers &&
              filteredCustomers.map((customer, index) => (
                <div
                  className={`BilledToSearch ${
                    index === selectedCustomerIndex ? "selected" : "NotSELECTED"
                  }`}
                  onClick={() => handleOnClick(customer)}
                  key={customer._id}
                >
                  {customer.firstName} {customer.lastName} | {customer.company}{" "}
                  | {customer.address}
                </div>
              ))}
          </BilledToContainer>
          <FromContainer>
            <Label htmlForm='from'>From:</Label>
            <span>
              {user && (
                <div>
                  <h4>{user.company}</h4>
                  <p className='FromAddressLabel'>{user.address}</p>
                </div>
              )}
            </span>
          </FromContainer>
        </InputContainer>
        <Table>
          <TableHead>
            <tr className='TableHeaderInvoiceForm'>
              <TableHeaderCell>S.No</TableHeaderCell>
              <TableHeaderCell>Product Name</TableHeaderCell>
              <TableHeaderCell>Size</TableHeaderCell>
              <TableHeaderCell>Quantity</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Total</TableHeaderCell>
              <TableHeaderCell className='actions'>Actions</TableHeaderCell>
            </tr>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={index}>
                <TableCell className='IndexCSS'>{index + 1}</TableCell>

                <TableCell>
                  <Input
                    type='text'
                    className='invoiceFormsInputsProductName'
                    value={item.productName || ""}
                    onChange={(e) => handleProductChange(index, e)}
                    // ref={productRef}
                    ref={(el) => {
                      productRef.current = el;
                      productNameRefs.current[index] = el;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "ArrowDown") {
                        e.preventDefault(); // Prevent cursor from moving in input
                        setSelectedProductIndex((prevIndex) =>
                          prevIndex < filteredProducts.length - 1
                            ? prevIndex + 1
                            : prevIndex
                        );
                      } else if (e.key === "ArrowUp") {
                        e.preventDefault(); // Prevent cursor from moving in input
                        setSelectedProductIndex((prevIndex) =>
                          prevIndex > 0 ? prevIndex - 1 : prevIndex
                        );
                      } else if (
                        e.key === "Enter" &&
                        filteredProducts[selectedProductIndex]
                      ) {
                        e.preventDefault(); // Prevent form submission
                        onSearchProduct(
                          filteredProducts[selectedProductIndex].productName,
                          index
                        );
                      }
                    }}
                  />
                  <button
                    className='BillButton'
                    onClick={() => onSearchProduct(item.productName, index)}
                  >
                    Search
                  </button>
                  <div className='invoiceFormsInputProductSearch'>
                    {products &&
                      products
                        .filter((product) => {
                          const searchProduct = item.productName.toLowerCase();
                          const fullName = product.productName.toLowerCase();

                          return (
                            searchProduct &&
                            fullName.toLowerCase().startsWith(searchProduct) &&
                            fullName.toLowerCase() !== searchProduct
                          );
                        })
                        .map((product, index) => (
                          <div
                            onClick={() =>
                              onSearchProduct(`${product.productName}`, index)
                            }
                            className={`ProductNameSearch ${
                              index === selectedProductIndex
                                ? "SProductslist"
                                : "NProductslist"
                            }`}
                            key={product._id}
                            product={product}
                          >
                            {product.productName} {product.size} |{" "}
                            {product.price}
                          </div>
                        ))}
                  </div>
                </TableCell>
                <TableCell>
                  <Input
                    type='text'
                    className='invoiceFormsInputs'
                    value={item.size || ""}
                    onChange={(e) =>
                      handleItemChange(index, "size", e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type='number'
                    ref={(el) => (quantityRefs.current[index] = el)}
                    className='invoiceFormsInputs'
                    value={item.quantity || ""}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onChange={(e) =>
                      handleItemChange(
                        index,
                        "quantity",
                        parseInt(e.target.value)
                      )
                    }
                  />
                </TableCell>
                <TableCell>
                  <Input
                    type='number'
                    className='invoiceFormsInputs'
                    value={item.price || ""}
                    ref={(el) => (priceRefs.current[index] = el)}
                    onChange={(e) =>
                      handleItemChange(index, "price", parseInt(e.target.value))
                    }
                    onKeyDown={(e) => handlePriceKeyDown(e, index)}
                  />
                </TableCell>
                <TableCell className='IndexCSS'>
                  {item.quantity * item.price}
                </TableCell>
                <TableCell className='actions'>
                  {index !== 0 && (
                    <IconContainer onClick={() => handleRemoveItem(index)}>
                      <RemoveCircleIcon />
                    </IconContainer>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <ButtonsContainer>
          <AddItemButton className='add-item-button' onClick={handleAddItem}>
            <AddCircleIcon /> Add Item
          </AddItemButton>
        </ButtonsContainer>
        <HorizontalLine />
        <GrandTotalSection>
          <GrandTotalLabel style={{ fontFamily: "Open Sans" }}>
            Grand Total:
          </GrandTotalLabel>
          <GrandTotalValue>{grandTotal}</GrandTotalValue>
        </GrandTotalSection>
        <ButtonsContainer2>
          <button className='PrintButton' onClick={handleClear}>
            Clear
          </button>
          <PrintButtonMain onClick={handlePrint}>
            <LuPrinter style={{ fontSize: "18px", marginRight: "4px" }} />
            Print
          </PrintButtonMain>
        </ButtonsContainer2>
      </InvoiceFormContainer>
    </PageContainer>
  );
};

export default InvoiceForm;
