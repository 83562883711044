import styled from "styled-components";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import "./components.css";
import toast from "react-hot-toast";
import { TbChalkboard } from "react-icons/tb";
import { MdOutlinePerson4 } from "react-icons/md";
import { MdOutlinePersonOutline } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { BiExtension } from "react-icons/bi";
import { LuSettings } from "react-icons/lu";
import { LuArchive } from "react-icons/lu";
import { TbReportAnalytics } from "react-icons/tb";
import { TbFileDollar } from "react-icons/tb";
import { TbHelp } from "react-icons/tb";
import { TbForms } from "react-icons/tb";
import { PiMoneyBold } from "react-icons/pi";
import { MdOutlinePersonPin } from "react-icons/md";
import { LuHardDrive } from "react-icons/lu";

const Leftside = (props) => {
  const { user } = useAuthContext();
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  const SaleCS = () => {
    toast("This feature is not currently available.", {
      duration: 3000,
    });
  };

  return (
    <Container>
      <SideBar>
        <UserInfo>
          <CardBackground />
          <Photo />
          <span>
            Welcome,{" "}
            {user && (
              <>
                <span>{user.firstName}</span>
              </>
            )}
          </span>
        </UserInfo>

        <Widget>
          <div>
            <Link
              className={`SideBarOptions ${
                isActive("/home") ? "activeLink" : ""
              }`}
              to='/home'
            >
              <div className='SideBarIconWithLabel'>
                <TbChalkboard style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faChalkboard} size='xl' /> */}
                Dashboard
              </div>
            </Link>
            <Link
              className={`SideBarOptions ${
                isActive("/products") ? "activeLink" : ""
              }`}
              to='/products'
            >
              <div className='SideBarIconWithLabel'>
                <LuArchive style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faBoxArchive} size='xl' /> */}
                Products
              </div>
            </Link>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <MdOutlinePersonPin style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Employee
              </div>
            </span>
            <Link
              className={`SideBarOptions ${
                isActive("/customers") ? "activeLink" : ""
              }`}
              to='/customers'
            >
              <div className='SideBarIconWithLabel'>
                <MdOutlinePersonOutline style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faUser} size='xl' /> */}
                Customers
              </div>
            </Link>
            <Link
              className={`SideBarOptions ${
                isActive("/vendors") ? "activeLink" : ""
              }`}
              to='/vendors'
            >
              <div className='SideBarIconWithLabel'>
                <MdOutlinePerson4 style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faUserTie} size='xl' /> */}
                Vendors
              </div>
            </Link>
            <Link
              className={`SideBarOptions ${
                isActive("/sales") ? "activeLink" : ""
              }`}
              to='/sales'
            >
              <div className='SideBarIconWithLabel'>
                <TbReportMoney style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileInvoiceDollar} size='xl' /> */}
                Sales
              </div>
            </Link>

            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <HiOutlineDocumentReport style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Purchase
              </div>
            </span>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <PiMoneyBold style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Payroll
              </div>
            </span>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <TbFileDollar style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Expenses
              </div>
            </span>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <TbReportAnalytics style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Reports
              </div>
            </span>
          </div>
          <LOWERdiv>
            <Link
              className={`SideBarOptions ${
                isActive("/extensions") ? "activeLink" : ""
              }`}
              to='/extensions'
            >
              <div className='SideBarIconWithLabel'>
                <BiExtension style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFlask} size="xl" />  */}
                Extensions
              </div>
            </Link>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <LuHardDrive style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Restore
              </div>
            </span>
            <span onClick={SaleCS} className='SideBarOptionsSales'>
              <div className='SideBarIconWithLabel'>
                <TbHelp style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faFileWaveform} size='xl' />  */}
                Help
              </div>
            </span>
            <Link
              className={`SideBarOptions ${
                isActive("/requests") ? "activeLink" : ""
              }`}
              to='/requests'
            >
              <div className='SideBarIconWithLabel'>
                <TbForms style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faGear} size='xl' /> */}
                Request feature
              </div>
            </Link>
            <Link
              className={`SideBarOptions ${
                isActive("/profile") ? "activeLink" : ""
              }`}
              to='/profile'
            >
              <div className='SideBarIconWithLabel'>
                <LuSettings style={{ fontSize: "22px" }} />
                {/* <FontAwesomeIcon icon={faGear} size='xl' /> */}
                Settings
              </div>
            </Link>
          </LOWERdiv>
        </Widget>
      </SideBar>
    </Container>
  );
};

const Container = styled.div`
  grid-area: leftside;
  width: 344px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const SideBar = styled.div`
  text-align: center;
  overflow: hidden;
  margin-bottom: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
  transition: box-shadow 83ms;
  position: relative;
  border: none;
  @media (max-width: 1366px) {
    margin-bottom: 4px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 4px;
  }
`;

const UserInfo = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 12px 12px 16px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1366px) {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    padding: 6px 6px 8px;
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
    padding: 6px 6px 8px;
    font-size: 14px;
  }
`;

const Photo = styled(PermIdentityIcon)`
  box-shadow: none;
  width: 72px;
  height: 72px;
  box-sizing: border-box;
  background-clip: content-box;
  background-color: white;
  background-position: center;
  background-size: 60%;
  background-repeat: no-repeat;
  border: 2px solid white;
  margin: -38px auto 12px;
  border-radius: 50%;
`;

const CardBackground = styled.div`
  background: url("/images/Solid_white.png");
  background-position: center;
  background-size: 462px;
  height: 54px;
  margin: -12px -12px 0;
`;

const Widget = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
  @media (max-width: 1366px) {
    padding-top: 6px;
    padding-bottom: 6px;

    & span {
      font-size: 12px;
    }
  }
  @media (max-width: 1280px) {
    padding-top: 6px;
    padding-bottom: 6px;

    & span {
      font-size: 12px;
    }
  }
`;

const LOWERdiv = styled.div`
  border-top: 1px solid;
  border-color: lightgrey;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 10px;
  @media (max-width: 1366px) {
    border-top: 0.5px solid;
    border-color: lightgrey;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
  }
  @media (max-width: 1280px) {
    border-top: 0.5px solid;
    border-color: lightgrey;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 5px;
  }
`;

export default Leftside;
