import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

const Footer = () => {
  return (
    <div>
      <FooterContainer>
        <FooterSection>
          <FooterLogo>accountloop</FooterLogo>
          <FooterDesc>a Rishav Chhabra production</FooterDesc>
        </FooterSection>

        <FooterSection>
          <FooterTitle>Features</FooterTitle>
          <FooterLink to='/'>Cash Flow Management</FooterLink>
          <FooterLink to='/'>Invoice System</FooterLink>
          <FooterLink to='/'>Manage Bills</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>Company</FooterTitle>
          <FooterLink to='/'>About</FooterLink>
          <FooterLink to='/'>Contact</FooterLink>
          <FooterLink to='/'>Support</FooterLink>
        </FooterSection>

        <FooterSection>
          <FooterTitle>Follow Us</FooterTitle>
          <SocialIcons>
            <SocialLink to={{ pathname: '/' }} target='_blank'><FaFacebookF /></SocialLink>
            <SocialLink to={{ pathname: '/' }} target='_blank'><FaTwitter /></SocialLink>
            <SocialLink to={{ pathname: '/' }} target='_blank'><FaLinkedinIn /></SocialLink>
          </SocialIcons>
        </FooterSection>
      </FooterContainer>
      <Copyright>© {new Date().getFullYear()} AccountLoop. All rights reserved.</Copyright>
    </div>
  );
};

const FooterContainer = styled.div`
  display: flex;
  background: #15171A;
  padding: 40px;
  color: #FFF;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  flex: 1;
  padding: 10px;
  min-width: 200px;
`;

const FooterLogo = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const FooterDesc = styled.p`
  opacity: 0.6;
`;

const FooterTitle = styled.h4`
  font-size: 20px;
  margin-bottom: 20px;
`;

const FooterLink = styled(Link)`
  display: block;
  text-decoration: none;
  color: inherit;
  margin-bottom: 10px;
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
`;

const SocialLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  font-size: 20px;
`;

const Copyright = styled.p`
  background: #0E1114;
  color: #FFF;
  text-align: center;
  padding: 20px;
  border-top: 1px solid lightgrey;
  border-width: 80%;
`;

export default Footer;

