import { useLogin } from "../../hooks/useLogin";
import { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import "./login.css";
import styled from "styled-components";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login, isloading, error } = useLogin();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await login(email, password);
    if (result && !error) {
      showToastWithPromise();
    }
  };

  const showToastWithPromise = async () => {
    toast.loading("Connecting to database...");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    toast.dismiss();
  };

  const handleforgetpass = () => {
    window.location.href = "/forgotpassword";
  }

  // ---------------------//

  return (
    <ContainerLogin className='login-card'>
      <Toaster />
      <form onSubmit={handleSubmit}>
        <h3 className='loginHeader'>Log in with your accountloop ID</h3>

        <label className='nameLabels'>Email:</label>
        <input
          type='email'
          className='inputLabels'
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <label className='nameLabels'>Password:</label>
        <input
          type='password'
          className='inputLabels'
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <FPDiv onClick={handleforgetpass}>Forgot Password?</FPDiv>

        <button
          type='submit'
          className='loginAccountButton'
          disabled={isloading}
        >
          Login
        </button>
        <p className='signinlink'>
          Can't log in ?{" "}
          <Link className='LoginLink' to='/signup'>
            {" "}
            Create an account
          </Link>
        </p>
        {error && (
          <div className='error'>
            <ErrorIcon className='errorIcon' />
            <span className='errorText'>{error}</span>
          </div>
        )}
      </form>
    </ContainerLogin>
  );
};

const ContainerLogin = styled.div`
  padding-top: 80px;
  border-radius: 8px;

  @media (max-width: 768px) {
    border-radius: 8px;
  }
`;

const FPDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: "Open Sans";
  font-size: 12px;
  color: rgba(0, 100, 255, 255);
  cursor: pointer;
  padding-block: 2px;
`;

export default Login;
