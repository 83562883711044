import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TbFileInvoice } from "react-icons/tb";
import { TbFolderFilled } from "react-icons/tb";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import styled from "styled-components";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  row: {
    cursor: "pointer",
  },
  iconCell: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "10px",
  },
});

const SaleByCompany = ({ sales, saleEntry: saleEntries, onTotalsUpdate }) => {
  const classes = useStyles();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);

  const salesByCompany = sales.reduce((acc, sale) => {
    const existingSales = acc[sale.customer.company] || [];
    existingSales.push(sale);
    acc[sale.customer.company] = existingSales;
    return acc;
  }, {});

  if (saleEntries && saleEntries.length > 0) {
    saleEntries.forEach((saleEntry) => {
      const existingEntries =
        salesByCompany[saleEntry?.invoices?.customer?.company] || [];
      existingEntries.push({
        ...saleEntry,
        invoiceNumber: saleEntry.invoices?.invoiceNumber, // assuming invoices as identifier
        debit: saleEntry.debit, // using debit as grandTotal
        date: saleEntry.createdAt, // using createdAt as date
      });
      salesByCompany[saleEntry?.invoices?.customer?.company] = existingEntries;
    });
  }

  for (let company in salesByCompany) {
    salesByCompany[company].sort((a, b) => new Date(b.date) - new Date(a.date));
  }

  //////////////////////////////////////
  const mostRecentDatesByCompany = Object.keys(salesByCompany).reduce(
    (acc, company) => {
      const mostRecentDate = salesByCompany[company].reduce(
        (latestDate, sale) => {
          const saleDate = new Date(sale.date);
          return saleDate > latestDate ? saleDate : latestDate;
        },
        new Date(0)
      );
      acc[company] = mostRecentDate;
      return acc;
    },
    {}
  );

  // const handleCompanyClick = (company) => {
  //   setSelectedCompany(company);
  // };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);

    // calculate total credit and debit
    let credit = 0,
      debit = 0;

    salesByCompany[company].forEach((sale) => {
      if (sale.grandTotal) credit += sale.grandTotal;
      if (sale.debit) debit += sale.debit;
    });

    setTotalCredit(credit);
    setTotalDebit(debit);
    onTotalsUpdate(credit, debit);
  };

  // const handleBackClick = () => {
  //   setSelectedCompany(null);
  // };

  const handleBackClick = () => {
    setSelectedCompany(null);
    setTotalCredit(0);
    setTotalDebit(0);
    onTotalsUpdate(0, 0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = Object.keys(salesByCompany).filter((company) =>
    company.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const formatDate = (dateString) => {
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <DisplayNone>
        {totalCredit}
        {totalDebit}
      </DisplayNone>

      <SearchFunctionality>
        Sale Records
        <input
          label='Search Companies'
          variant='outlined'
          placeholder='Search by company'
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </SearchFunctionality>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table' size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Company Name</TableCell>
              <TableCell align='right'>Type</TableCell>
              {selectedCompany && [
                <TableCell align='right' key='credit'>
                  Credit
                </TableCell>,
                <TableCell align='right' key='debit'>
                  Debit
                </TableCell>,
              ]}

              <TableCell align='right'>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedCompany === null ? (
              filteredCompanies.map((company) => (
                <TableRow
                  key={company}
                  onClick={() => handleCompanyClick(company)}
                  className={classes.row}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.iconCell}
                  >
                    <TbFolderFilled
                      style={{ fontSize: "22px", color: "#54aeff" }}
                      className={classes.icon}
                    />
                    {company}
                  </TableCell>
                  <TableCell align='right'>folder</TableCell>
                  <TableCell align='right'>
                    updated{" "}
                    {formatDistanceToNow(mostRecentDatesByCompany[company])} ago
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <>
                <TableRow onClick={handleBackClick} className={classes.row}>
                  <TableCell
                    component='th'
                    scope='row'
                    className={classes.iconCell}
                  >
                    <TbFolderFilled
                      style={{ fontSize: "22px", color: "#54aeff" }}
                      className={classes.icon}
                    />
                    ...
                  </TableCell>
                  <TableCell align='right'></TableCell>
                  {selectedCompany && (
                    <>
                      <TableCell align='right'></TableCell>
                      <TableCell align='right'></TableCell>
                    </>
                  )}
                  <TableCell align='right'></TableCell>
                </TableRow>
                {salesByCompany[selectedCompany].map((sale, index) => (
                  <TableRow
                    key={`${sale.invoiceNumber}-${index}`}
                    className={classes.row}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      className={classes.iconCell}
                    >
                      <TbFileInvoice style={{ fontSize: "22px" }} />
                      {sale.invoiceNumber}
                    </TableCell>
                    <TableCell align='right'>Invoice file</TableCell>
                    <TableCell align='right'>
                      {sale.grandTotal && <div>₹ {sale.grandTotal}</div>}
                    </TableCell>
                    <TableCell align='right'>
                      {sale.debit && <div>₹ {sale.debit}</div>}
                    </TableCell>
                    <TableCell align='right'>{formatDate(sale.date)}</TableCell>
                  </TableRow>
                ))}
              </>
            )}

            {/* ///////////////////////////////// */}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const SearchFunctionality = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1em;
  font-family: "Open Sans";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);

  & > input {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    width: 260px;
  }
  @media (max-width: 1366px) {
    padding: 5px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }

  @media (max-width: 1280px) {
    padding: 5px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }
`;

const DisplayNone = styled.div`
  display: none;
`;

export default SaleByCompany;
