import React from "react";
import { useState } from "react";
import axios from "axios";
import PasswordChangeSuccess from "./passwordChangeSuccess";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const Forgetpass = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otpEntered, setOtpEntered] = useState(false);
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();


  const handleForgotPassword = async () => {
    try {
      const res = await axios.post(`${API_URL}/api/users/forgotPassword`, {
        email,
      });
      toast.success(res.data.message);
      setOtpSent(true);
    } catch (err) {
      toast.error(err.response.data.error); // Set error message from server
    }
  };

  const handleOtpEntry = (e) => {
    setOtp(e.target.value);
    if (e.target.value.length === 6) {
      setOtpEntered(true);
    }
  };

  const handleResetPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      toast.error("Passwords do not match!");
      return;
    }
    try {
      const res = await axios.post(`${API_URL}/api/users/resetPassword`, {
        email,
        otp,
        newPassword,
      });
      toast.success(res.data.message);
      setPasswordChangeSuccess(true);
      navigate("/rpsuccess"); // Set success state when no error is caught
    } catch (err) {
      toast.error(err.response.data.error); // Set error message from server
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <div className='login-card'>
      <h1>Reset Password</h1>
      {!otpSent && (
        <>
          <TextField
            type='text'
            label='Email'
            value={email}
            style={{ fontFamily: "Open Sans" }}
            onChange={(e) => setEmail(e.target.value)}
            variant='outlined'
            fullWidth
            margin='normal'
            size='small'
          />
          <Button
            variant='contained'
            style={{
              backgroundColor: "rgba(0,100,255,255)",
              color: "#ffffff",
              borderRadius: "4px",
              fontSize: "12px",
              fontFamily: "Open Sans",
            }}
            onClick={handleForgotPassword}
            size='large'
          >
            Send OTP
          </Button>
        </>
      )}

      {otpSent && !passwordChangeSuccess && (
        <>
          <TextField
            type='number'
            label='OTP'
            value={otp}
            onChange={handleOtpEntry}
            style={{ fontFamily: "Open Sans", fontSize: "12px" }}
            variant='outlined'
            fullWidth
            margin='normal'
            size='small'
          />
          {otpEntered && (
            <>
              <TextField
                type={showPassword ? "text" : "password"}
                label='New password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                style={{ fontFamily: "Open Sans" }}
                variant='outlined'
                fullWidth
                margin='normal'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                type={showConfirmPassword ? "text" : "password"}
                label='Confirm new password'
                value={confirmNewPassword}
                style={{ fontFamily: "Open Sans" }}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                variant='outlined'
                fullWidth
                margin='normal'
                size='small'
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={handleClickShowConfirmPassword}>
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant='contained'
                style={{
                  backgroundColor:
                    newPassword !== confirmNewPassword
                      ? "#aaaaaa"
                      : "rgba(0,100,255,255)",
                  color: "#ffffff",
                  borderRadius: "4px",
                  fontSize: "12px",
                  fontFamily: "Open Sans",
                }}
                onClick={handleResetPassword}
                size='large'
                disabled={newPassword !== confirmNewPassword} // Button will be disabled if passwords don't match
              >
                Reset Password
              </Button>
            </>
          )}
        </>
      )}
      {passwordChangeSuccess && <PasswordChangeSuccess />}
    </div>
  );
};
export default Forgetpass;
