import styled from "styled-components";
import Leftside from "../components/Leftside";
import Rightside from "../components/Rightside";
import Request from "../pages/request/request";

const ReqDashboard = (props) => {
  return (
    <Container>
      <Layout>
        <Leftside />
        <Request />
        <Rightside />
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 52px;
  max-width: 100%;
  margin-right: 10px;
  background-color: white;
  /* ------- */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -10;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  @media (max-width: 1366px) {
    padding-top: 52px;
    max-width: 100%;
    margin-right: 10px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -10;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  @media (max-width: 1280px) {
    padding-top: 52px;
    max-width: 100%;
    margin-right: 10px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -10;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
`;

const Layout = styled.div`
  display: grid;
  margin-left: 10px;
  grid-gap: 10px;
  padding: 0 100px;
  grid-template-areas: "leftside main rightside";
  grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
  column-gap: 25px;
  row-gap: 25px;
  grid-template-rows: auto;
  /* grid-template-columns: auto; */
  margin: 25px 0;
  @media (max-width: 1366px) {
    display: grid;
    margin-left: 10px;
    grid-gap: 10px;
    padding: 0 20px;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    /* grid-template-columns: auto; */
    margin: 25px 0;
  }
  @media (max-width: 1280px) {
    display: grid;
    margin-left: 10px;
    grid-gap: 10px;
    padding: 0 20px;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    /* grid-template-columns: auto; */
    margin: 25px 0;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
`;

export default ReqDashboard;
