import { createContext, useReducer } from "react";

export const SaleEntriesContext = createContext();

export const saleentriesReducer = (state, action) => {
  switch (action.type) {
    case "SET_SALEENTRIES":
      return {
        saleentries: action.payload,
      };
    case "CREATE_SALEENTRY":
      return {
        saleentries: [action.payload, ...state.saleentries],
      };
    case "DELETE_SALEENTRY":
      return {
        saleentries: state.saleentries.filter((saleentry) => saleentry._id !== action.payload),
      };
    case "EDIT_SALEENTRY":
      return {
        saleentries: state.saleentries.map((saleentry) => 
          saleentry._id === action.payload._id ? action.payload : saleentry),
      };
    default:
      return state;
  }
};

export const SaleEntriesContextProvider = ({ children }) => {
  const [state, saleEntrydispatch] = useReducer(saleentriesReducer, {
    saleentries: [],
  });

  return (
    <SaleEntriesContext.Provider value={{ ...state, saleEntrydispatch }}>
      {children}
    </SaleEntriesContext.Provider>
  );
};
