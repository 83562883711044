import { useState, useRef } from "react";
import { useProductsContext } from "../../hooks/useProductsContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import styled from "styled-components";
import axios from "axios";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const ProductForm = () => {
  const { dispatchProduct } = useProductsContext();
  const { user } = useAuthContext();
  const [productName, setProductName] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const productNameRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in");
      return;
    }

    const product = {
      productName,
      size,
      price,
    };

    try {
      const response = await axios.post(`${API_URL}/api/products`, product, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      // Reset the form and show success message
      setProductName("");
      setSize("");
      setPrice("");
      setError(null);
      setEmptyFields([]);
      dispatchProduct({ type: "CREATE_PRODUCT", payload: response.data });
      toast.success("New Product Added");
    } catch (error) {
      if (error.response && error.response.data) {
        // This is a server validation error
        setError(error.response.data.error);
        setEmptyFields(error.response.data.emptyFields || []); // <-- Here
        toast.error(error.response.data.error);
      } else {
        // This is a network error or other unexpected error
        setError(error.message);
        setEmptyFields([]);
        toast.error(error.message);
      }
    }
  };

  const isFormValid = productName && size && price;

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit}>
        <h3 className='ACCF'>Add a New Product</h3>

        <FormGroup>
          <label>Product Name:</label>
          <input
            type='text'
            onChange={(e) => setProductName(e.target.value)}
            value={productName}
            autoFocus
            ref={productNameRef}
            className={emptyFields.includes("productName") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Size:</label>
          <input
            type='text'
            onChange={(e) => setSize(e.target.value)}
            value={size}
            className={emptyFields.includes("size") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Price:</label>
          <input
            type='number'
            onChange={(e) => setPrice(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                productNameRef.current.focus();
                handleSubmit(e);
              }
            }}
            value={price}
            className={emptyFields.includes("price") ? "error" : ""}
          />
        </FormGroup>
        <ButtonContainer>
          <button disabled={!isFormValid}>Add Product</button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 320px;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-family: "Open Sans";
    color: #303030;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: lightgrey;
    outline: none;
    font-size: 14px;
    position: relative;
    font-family: "Open Sans";
    flex: 1;
    margin-left: 10px;
  }

  .error {
    border-color: red;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  margin-top: 10px;
  width: 360px;

  button {
    padding: 10px 16px;
    width: 160px;
    background-color: rgba(0, 100, 225, 255);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
  button:disabled {
    padding: 10px 16px;
    width: 160px;
    background-color: darkgray;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); */
    color: white;
    cursor: not-allowed;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
`;

const ErrorMessage = styled.div`
  display: none;
  color: red;
  margin-top: 20px;
  margin-top: 5px;
`;

export default ProductForm;
