import { useAuthContext } from "./useAuthContext";
import { useCustomersContext } from "./useCustomersContext";
import { useInvoicesContext } from "./useInvoicesContext";
import { useProductsContext } from "./useProductsContext";
import { useRequestsContext } from "./useRequestsContext";
import { useSaleEntriesContext } from "./useSaleEntriesContext";
import { useVendorsContext } from "./useVendorsContext";

const useLogout = () => {
  const { dispatch } = useAuthContext();
  const { dispatch: customersDispatch } = useCustomersContext();
  const { dispatchProduct: productsDispatch } = useProductsContext();
  const { invoicedispatch } = useInvoicesContext();
  const { dispatchRequest } = useRequestsContext();
  const { saleEntrydispatch } = useSaleEntriesContext();
  const { dispatch: vendorsDispatch } = useVendorsContext();

  const logout = () => {
    //Remove User from local storage
    localStorage.removeItem("user");

    //Dispatch logout action
    dispatch({ type: "LOGOUT" });
    customersDispatch({ type: "SET_CUSTOMERS", payload: null });
    productsDispatch({ type: "SET_PRODUCTS", payload: null });
    invoicedispatch({ type: "SET_INVOICES", payload: null });
    dispatchRequest({ type: "SET_REQUESTS", payload: null });
    saleEntrydispatch({ type: "SET_SALEENTRIES", payload: null });
    vendorsDispatch({ type: "SET_VENDORS", payload: null });
  };
  return { logout };
};

export default useLogout;
