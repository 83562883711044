import React, { useState, useEffect } from "react";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { LuTrash } from "react-icons/lu";
import axios from "axios";
import { useInvoicesContext } from "../../hooks/useInvoicesContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast, { Toaster } from "react-hot-toast";
import "./sale.css";
import SaleFilterSwitch from "./saleFilterSwitch";
import SaleByCompany from "./saleByCompany";
import { useSaleEntriesContext } from "../../hooks/useSaleEntriesContext";
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineFilterAlt } from "react-icons/md";
import { TbArrowsSort } from "react-icons/tb";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const SaleDetails = ({ sales, saleEntry }) => {
  const { invoicedispatch } = useInvoicesContext();
  const { saleEntrydispatch } = useSaleEntriesContext();
  const { user } = useAuthContext();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredSales, setFilteredSales] = useState(sales);
  const [selected, setSelected] = useState("allrecords");
  const [tableData, setTableData] = useState([]);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCreditBC, setTotalCreditBC] = useState(0);
  const [totalDebitBC, setTotalDebitBC] = useState(0);

  /////////////////////////////////////////////////////
  const [sortOption, setSortOption] = useState("Latest"); // Default option is A-Z
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const sortOptions = [
    { value: "AtoZ", label: "Name A-Z" },
    { value: "ZtoA", label: "Name Z-A" },
    { value: "Latest", label: "Latest First" },
    { value: "Oldest", label: "Oldest First" },
  ];
  /////////////////////////////////////////////////////

  useEffect(() => {
    // console.log("Sales:", sales);
    // console.log("Sale Entry:", saleEntry);
    if (sales && saleEntry) {
      setTableData([...sales, saleEntry]);
    }
  }, [sales, saleEntry]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //////////////////////////////////////////

  useEffect(() => {
    let filteredData = tableData
      .filter((item) => {
        const itemDate = item.createdAt ? new Date(item.createdAt) : null;
        const startDateObj = startDate ? new Date(startDate) : null;
        const endDateObj = endDate ? new Date(endDate) : null;

        if (startDateObj && endDateObj && itemDate) {
          return itemDate >= startDateObj && itemDate <= endDateObj;
        } else {
          return true;
        }
      })
      .filter((item) => {
        const companyName = item.company || item.customer?.company || "";
        const invoiceNumber =
          item.invoiceNumber || item.invoices?.invoiceNumber || "";
        const description =
          item.description ||
          (item.products
            ? item.products
                .map((productObj) => productObj.product.productName)
                .join(", ")
            : "");
        const lowerCasedFilter = searchTerm.toLowerCase();
        return (
          companyName.toLowerCase().includes(lowerCasedFilter) ||
          invoiceNumber.toLowerCase().includes(lowerCasedFilter) ||
          description.toLowerCase().includes(lowerCasedFilter)
        );
      });

    switch (sortOption) {
      case "AtoZ":
        filteredData.sort((a, b) => {
          if (typeof a.company === "string" && typeof b.company === "string") {
            return a.company.localeCompare(b.company);
          } else {
            return 0;
          }
        });
        break;
      case "ZtoA":
        filteredData.sort((a, b) => {
          if (typeof a.company === "string" && typeof b.company === "string") {
            return b.company.localeCompare(a.company);
          } else {
            return 0;
          }
        });
        break;
      case "Latest":
      case "Default":
        filteredData.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        break;
      case "Oldest":
        filteredData.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
        break;
      default:
        break;
    }

    const salesWithSNo = filteredData.map((item, index) => ({
      ...item,
      sNo: index + 1,
    }));

    setFilteredSales(salesWithSNo);
  }, [tableData, searchTerm, sortOption, startDate, endDate]);

  //////////////////////////////////////////////////

  const handleClick = async () => {
    if (!user || !selectedSale) {
      return;
    }

    try {
      if (selectedSale.type === "sale") {
        await axios.delete(`${API_URL}/api/sales/${selectedSale._id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        invoicedispatch({ type: "DELETE_CUSTOMER", payload: selectedSale._id });
        toast.success("Sale deleted");
      } else if (selectedSale.type === "saleEntry") {
        await axios.delete(`${API_URL}/api/saleEntry/${selectedSale._id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        saleEntrydispatch({
          type: "DELETE_SALEENTRY",
          payload: selectedSale._id,
        });
        toast.success("Sale Entry deleted");
      }
    } catch (error) {
      toast.error("Error: Action failed!");
    }

    setIsDeletePopupOpen(false);
    setSelectedSale(null);
  };

  ////////////////////////////////////////////////////

  const confirmDelete = (sale) => {
    setSelectedSale(sale);
    setIsDeletePopupOpen(true);
  };

  const cancelDelete = () => {
    setIsDeletePopupOpen(false);
    setSelectedSale(null);
  };

  // inside your useEffect
  useEffect(() => {
    if (sales && saleEntry) {
      const unifiedSales = sales.map((sale) => ({
        ...sale,
        description: null,
        debit: null,
        type: "sale", // Identifies this record as a sale
      }));

      const unifiedSaleEntries = saleEntry.map((entry) => {
        try {
          return {
            ...entry,
            company: entry.invoices?.customer?.company || null,
            invoiceNumber: entry.invoices?.invoiceNumber || null,
            type: "saleEntry", // Identifies this record as a sale entry
          };
        } catch (error) {
          console.error("Error in map function:", error);
          return {
            ...entry,
            company: null,
            invoiceNumber: null,
          };
        }
      });

      const combinedData = [...unifiedSales, ...unifiedSaleEntries];
      const sortedData = combinedData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      const totalCredit = combinedData.reduce(
        (total, record) => total + (record.grandTotal || 0),
        0
      );
      const totalDebit = combinedData.reduce(
        (total, record) => total + (record.debit || 0),
        0
      ); // calculates totalDebit

      setTableData(sortedData);
      setTotalCredit(totalCredit);
      setTotalDebit(totalDebit); // sets totalDebit to the calculated value
    }
  }, [sales, saleEntry]);

  // updated column definition
  const columns = [
    {
      name: "S.no",
      cell: (row) => <div style={{ width: "" }}>{row.sNo}</div>,
      sortable: true,
      grow: 0,
    },
    {
      name: "Company",
      selector: (row) =>
        row.customer?.company ? row.customer?.company : row.company || "N/A",
    },

    {
      name: "Invoice",
      selector: (row) => row.invoiceNumber || "N/A",
    },
    {
      name: "Description",
      cell: (row) =>
        row.description ||
        (row.products
          ? row.products
              .map((productObj) => productObj.product.productName)
              .join(", ")
          : "N/A"),
    },
    {
      name: "Credit",
      selector: (row) => row.grandTotal,
    },
    {
      name: "Debit",
      selector: (row) => row.debit,
    },
    {
      name: "Actions",
      cell: (row) => (
        <LuTrash
          style={{ fontSize: "18px", cursor: "pointer" }}
          onClick={() => confirmDelete(row)}
        />
      ),
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "30px", // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: windowWidth <= 1280 ? "0.8em" : "1em", // change the font size of header
      },
    },
    cells: {
      style: {
        fontSize: windowWidth <= 1280 ? "0.8em" : "1em", // change the font size of cells
      },
    },
    columns: {
      style: {
        paddingLeft: "8px", // Align text in cells to the left
        paddingRight: "8px",
      },
    },
  };

  const handleTotalsUpdate = (totalCreditBC, totalDebitBC) => {
    setTotalCreditBC(totalCreditBC);
    setTotalDebitBC(totalDebitBC);
  };

  return (
    <SaleDetail>
      <Toaster />
      {selected === "allrecords" && (
        <OverviewWH>
          <h3>Overview</h3>
          <Overview>
            <div className='overviewCard2'>
              <h4>Total Credit:</h4>
              <p>₹ {totalCredit}</p>
            </div>
            <div className='overviewCard3'>
              <h4>Total Debit:</h4>
              <p>₹ {totalDebit}</p>
            </div>
            <div className='overviewCard4'>
              <h4>Net Balance:</h4>
              <p>₹ {totalCredit - totalDebit}</p>
            </div>
          </Overview>
        </OverviewWH>
      )}
      {selected === "bycompany" && (
        <OverviewWH>
          <h3>Overview</h3>
          <Overview>
            <div className='overviewCard2'>
              <h4>Total Credit:</h4>
              <p>₹ {totalCreditBC}</p>
            </div>
            <div className='overviewCard3'>
              <h4>Total Debit:</h4>
              <p>₹ {totalDebitBC}</p>
            </div>
            <div className='overviewCard4'>
              <h4>Net Balance:</h4>
              <p>₹ {totalCreditBC - totalDebitBC}</p>
            </div>
          </Overview>
        </OverviewWH>
      )}
      <div>
        <SaleFilterSwitch selected={selected} setSelected={setSelected} />
      </div>
      {selected === "allrecords" && (
        <>
          <SearchFunctionality>
            Sale Records
            <SortBy>
              <SortByFunction>
                <SortByTxt>
                  <TbArrowsSort style={{ fontSize: "16px" }} />
                  Sort :
                </SortByTxt>
                <Select
                  styles={CustomStyles}
                  options={sortOptions}
                  defaultValue={sortOptions[0]}
                  isSearchable={false}
                  onChange={(option) => setSortOption(option.value)}
                />
              </SortByFunction>
              <div>
                <FilterButton
                  onClick={() => setShowDatePicker(!showDatePicker)}
                >
                  <MdOutlineFilterAlt style={{ fontSize: "18px" }} />
                  Filter
                </FilterButton>
                {showDatePicker && (
                  <CustomDatePickerWrapper>
                    <ResetButton
                      onClick={() => {
                        setStartDate(null);
                        setEndDate(null);
                      }}
                    >
                      Reset
                    </ResetButton>
                    <DatePicker.RangePicker
                      value={[startDate, endDate]}
                      onChange={(dates) => {
                        setStartDate(dates ? dates[0] : null);
                        setEndDate(dates ? dates[1] : null);
                      }}
                      format='YYYY/MM/DD'
                    />
                  </CustomDatePickerWrapper>
                )}
              </div>
              <input
                type='text'
                placeholder='Search customers'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SortBy>
          </SearchFunctionality>
          <DataTable
            title='Sales'
            columns={columns}
            data={filteredSales}
            noHeader
            dense
            customStyles={customStyles}
            pagination // enable pagination
            paginationPerPage={8} // 10 rows per page
          />{" "}
        </>
      )}

      {isDeletePopupOpen && (
        <div className='popup'>
          <div className='popup-content'>
            {/* <p>Are you sure you want to delete this sale?</p> */}
            <p>
              Are you sure you want to delete this
              {selectedSale && selectedSale.type === "sale"
                ? " Invoice"
                : " sale entry"}
              ?
            </p>
            <div className='button-container'>
              <button className='NCFCSS' onClick={cancelDelete}>
                No
              </button>
              <button className='YCFCSS' onClick={handleClick}>
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {selected === "bycompany" && (
        <SaleByCompany
          sales={sales}
          saleEntry={saleEntry}
          onTotalsUpdate={handleTotalsUpdate}
        />
      )}
    </SaleDetail>
  );
};

const SaleDetail = styled.div`
  padding: 1rem;
  @media (max-width: 1366px) {
    padding: 0.5rem;
  }

  @media (max-width: 1280px) {
    padding: 0.5rem;
  }
`;

const SearchFunctionality = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  font-size: 1em;
  font-family: "Open Sans";
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);

  & > input {
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    width: 260px;
  }
  @media (max-width: 1366px) {
    padding: 5px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }

  @media (max-width: 1280px) {
    padding: 5px;

    & > input {
      padding: 0.25rem;
      border-radius: 0.25rem;
      width: 200px;
      font-size: 0.8em;
    }
  }
`;

const Overview = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px;
  background: white;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding-top: 20px;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 20px;
    font-family: "Open Sans";
    border-radius: 15px;
    /* border: 1px solid lightgrey; */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

    & > h4 {
      font-size: 12px;
      font-family: "Open Sans";
    }

    & > p {
      font-size: 18px;
    }
  }

  .overviewCard1 {
    background: linear-gradient(to bottom, #ffffff, #ff87a2);
  }

  .overviewCard2 {
    /* background: linear-gradient(49deg, white 62%, #ffdbfd, #ff4c70, #ff7e5f, #ffa14f, #ffd53e); */
    /* background-color: #eee1fc;
    color: #6705fa ; */
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: white;
  }

  .overviewCard3 {
    /* background: linear-gradient(49deg, white 62%, #ffdbfd, #ff4c70, #ff7e5f, #ffa14f, #ffd53e); */
    /* background-color: #e6f2fe;
    color: rgba(0, 100, 255, 255); */
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: white;
  }

  .overviewCard4 {
    /* background: linear-gradient(49deg, white 62%, #ffdbfd, #ff4c70, #ff7e5f, #ffa14f, #ffd53e); */
    /* background-color: #ecfad7;
    color: #406b00; */
    /* background-color: rgba(0, 0, 0, 0.1); */
    background-color: white;
  }

  @media (max-width: 1366px) {
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 20%;
      padding-top: 20px;
      padding-left: 34px;
      padding-right: 34px;
      padding-bottom: 20px;
      font-family: "Open Sans";
      border-radius: 15px;
      /* border: 1px solid lightgrey; */
      /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
    }
  }

  @media (max-width: 1280px) {
    margin-bottom: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
  }
`;

const OverviewWH = styled.div`
  border: 1px solid lightgrey;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  border-radius: 8px;
  margin-bottom: 10px;

  @media (max-width: 1366px) {
    margin-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
  }
  @media (max-width: 1280px) {
    margin-bottom: 4px;
    padding-top: 4px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 0px;
  }
`;

const CustomStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderRadius: "8px",
    borderColor: "lightgrey",
    backgroundColor: "white",
    padding: "0px",
    fontSize: "12px",
    fontWeight: "500",
    width: "120px",
    height: "8px",
    color: "black",
    fontFamily: "Open Sans",
    outline: state.isFocused ? "none" : provided.outline,
    boxShadow: state.isFocused ? "none" : provided.boxShadow, // Add this line
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "8px",
    borderColor: "#E0E7EE",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    marginTop: "1px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected || state.isFocused ? "black" : "#3B3F45",
    backgroundColor: state.isSelected
      ? "darkgrey"
      : state.isFocused
      ? "lightgrey"
      : "white",
    padding: "8px",
    fontSize: "12px",
    cursor: "pointer",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3B3F45",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "black",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
};

const SortBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;

  & > input {
    padding: 0.7rem;
    border-radius: 0.5rem;
    border: 1px solid lightgray;
    width: 180px;
  }

  @media (max-width: 1366px) {
    font-size: 12px;
    column-gap: 4px;
    & > input {
      padding: 0.7rem;
      border-radius: 0.5rem;
      border: 1px solid lightgray;
      width: 130px;
    }
  }
`;

const FilterButton = styled.button`
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
  &:hover {
    background-color: #e4e7eb;
  }
`;

const CustomDatePickerWrapper = styled.div`
  position: absolute;
  z-index: 999;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-top: 6px;
`;

const ResetButton = styled.button`
  background-color: rgba(0, 100, 255, 255);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    background-color: #e4e7eb;
  }
`;

const SortByTxt = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 4px;
  font-size: 12px;
`;

const SortByFunction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid lightgrey;
  padding-left: 8px;
  padding-right: 8px;
`;

export default SaleDetails;
