import styled from "styled-components";
import DescriptionIcon from "@mui/icons-material/Description";
import React, { useState, useRef, useEffect, useCallback } from "react";
import "./components.css";
import { TbReportMoney } from "react-icons/tb";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { TbFileDollar } from "react-icons/tb";

const Rightside = (props) => {
  const [showOptions, setShowOptions] = useState(false); // Initialize state
  const [focusedOption, setFocusedOption] = useState(-1); // For keyboard navigation
  const optionRefs = useRef([]); // Refs for each option

  // Function to toggle showOptions state
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleSaleInvoice = () => {
    window.location.href = "/invoice";
  };

  const handleKeyDown = useCallback((e) => {
    if (showOptions) {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        const nextOption = (focusedOption + 1) % optionRefs.current.length;
        setFocusedOption(nextOption);
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const prevOption = (focusedOption - 1 + optionRefs.current.length) % optionRefs.current.length;
        setFocusedOption(prevOption);
      } else if (e.key === "Enter") {
        e.preventDefault();
        if (focusedOption !== -1) {
          optionRefs.current[focusedOption].click();
        }
      }
    }
  }, [showOptions, focusedOption]);
  
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (focusedOption !== -1 && showOptions) {
      optionRefs.current[focusedOption].focus();
    }
  }, [focusedOption, showOptions]);

  const options = [
    {
      name: "Sale Invoice",
      icon: <TbReportMoney style={{ fontSize: "22px" }} />,
      onClick: handleSaleInvoice,
    },
    {
      name: "Purchase Invoice",
      icon: <HiOutlineDocumentReport style={{ fontSize: "22px" }} />,
    },
    {
      name: "Expense Invoice",
      icon: <TbFileDollar style={{ fontSize: "22px" }} />,
    },
  ];

  return (
    <Container>
      <SideOptions>
        <Title>
          <h2>Panel</h2>
        </Title>
        <NoName2>
          <CreateInvoice
            className='CreateInvoiceButton'
            onClick={toggleOptions}
          >
            <IconContainer>
              <DescriptionIcon />
            </IconContainer>
            Create Invoice
          </CreateInvoice>
          {showOptions && (
            <InvoiceOptions>
              {options.map((option, index) => (
                <li
                  key={index}
                  className={focusedOption === index ? "selectedCI-option" : ""}
                  ref={(el) => (optionRefs.current[index] = el)}
                  onClick={option.onClick}
                >
                  {option.icon}
                  {option.name}
                </li>
              ))}
            </InvoiceOptions>
          )}
        </NoName2>
        <AlertBox>
          <div className='icon'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-13h-2v7h2zm0 4h-2v2h2z' />
            </svg>
          </div>
          <div className='content'>
            <h3>Warning</h3>
            <p>This Website is currently under development.</p>
          </div>
        </AlertBox>
        <AlertBox2>
          <div className='icon'>
            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'>
              <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-11h-2v6h2zm0 4h-2v2h2z' />
            </svg>
          </div>
          <div className='content'>
            <h3>Alert</h3>
            <p>Some of the features may not work.</p>
          </div>
        </AlertBox2>
      </SideOptions>
    </Container>
  );
};

const Container = styled.div`
  grid-area: rightside;
  width: 464px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const SideOptions = styled.div`
  text-align: center;
  overflow: hidden;
  margin-bottom: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  position: relative;
  border: none;
  padding: 12px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const Title = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-size: 9px;
  width: 100%;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Open Sans";
  line-height: 3;
  font-weight: bolder;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const NoName2 = styled.div`
  margin-top: 10px;
  width: 208px;
  justify-content: center;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const CreateInvoice = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 100, 225, 255);
  font-family: "Open Sans";
  height: 56px;
  width: 100%;
  border-radius: 10px;
  vertical-align: middle;
  z-index: 0;
  transition-duration: 167ms;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  color: white;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1366px) {
    width: 80%;
    font-size: 14px;
  }
  @media (max-width: 1280px) {
    width: 80%;
    font-size: 14px;
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const AlertBox = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 10px;
  width: 100%;
  max-width: 400px;
  font-family: "Open Sans";

  @media (max-width: 1366px) {
    width: 89%;
  }

  @media (max-width: 1280px) {
    width: 89%;
  }

  @media (max-width: 768px) {
    width: 88%;
    max-width: 400px;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffd2d2;
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      fill: #f55a5a;
    }
  }

  .content {
    flex: 1;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }
`;

const AlertBox2 = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  font-family: "Open Sans";

  @media (max-width: 1366px) {
    width: 89%;
  }

  @media (max-width: 1280px) {
    width: 89%;
  }

  @media (max-width: 768px) {
    width: 88%;
    max-width: 400px;
  }

  .icon {
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #ffeb99;
    border-radius: 50%;

    svg {
      width: 24px;
      height: 24px;
      fill: #e6b800;
    }
  }

  .content {
    flex: 1;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    p {
      font-size: 14px;
      color: #555;
    }
  }
`;

const InvoiceOptions = styled.div`
  /* Add some style here to position your options */
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 2px;

  & > li {
    margin-bottom: 10px;
    cursor: pointer;
    list-style-type: none;
    padding: 10px;
    width: 186px;
    display: flex;
    column-gap: 4px;
    align-items: center;
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 15px;
    &:hover {
      background-color: lightgray;
      border-radius: 6px;
    }
  }
`;

export default Rightside;
