import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { CustomersContextProvider } from "./context/customerContext";
import { ProductsContextProvider } from "./context/productContext";
import { AuthContextProvider } from "./context/authContext";
import { ExtensionsContextProvider } from "./context/extensionContext";
import { VendorsContextProvider } from "./context/vendorContext";
import { InvoicesContextProvider } from "./context/invoiceContext";
import { SaleEntriesContextProvider } from "./context/saleEntryContext";
import { RequestsContextProvider } from "./context/requestContext";

createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AuthContextProvider>
      <CustomersContextProvider>
        <VendorsContextProvider>
          <ProductsContextProvider>
            <ExtensionsContextProvider>
              <InvoicesContextProvider>
                <SaleEntriesContextProvider>
                  <RequestsContextProvider>
                    <App />
                  </RequestsContextProvider>
                </SaleEntriesContextProvider>
              </InvoicesContextProvider>
            </ExtensionsContextProvider>
          </ProductsContextProvider>
        </VendorsContextProvider>
      </CustomersContextProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
