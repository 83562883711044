import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
} from "react";
import styled from "styled-components";
import { TbSearch } from "react-icons/tb";
import useLogout from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import "./components.css";
import { Toaster } from "react-hot-toast";
import { useExtensionsContext } from "../hooks/useExtensionsContext";
import DarkModeSwitch from "../extensions/Dark mode/darkmodeExtensionFunctionality";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { AiFillSetting } from "react-icons/ai";
import { HiOutlineLogout } from "react-icons/hi";
import { BiHelpCircle } from "react-icons/bi";
import { TbForms } from "react-icons/tb";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import toast from "react-hot-toast";
import * as math from "mathjs";
import { LuCommand } from "react-icons/lu";
import { LuSpace } from "react-icons/lu";
import { TbCalculator } from "react-icons/tb";
import { LuBell } from "react-icons/lu";

const ActionTypes = {
  ARROW_DOWN: "ARROW_DOWN",
  ARROW_UP: "ARROW_UP",
  ENTER: "ENTER",
  RESET: "RESET",
};

// Declare a reducer
const reducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.ARROW_UP:
      return state > 0 ? state - 1 : 0;
    case ActionTypes.ARROW_DOWN:
      return state < action.payload - 1 ? state + 1 : action.payload - 1;
    case ActionTypes.ENTER:
      window.location.href = action.payload;
      return state;
    case ActionTypes.RESET:
      return -1;
    default:
      return state;
  }
};

const Header = () => {
  const { logout } = useLogout();
  const { user } = useAuthContext();
  const { extensions } = useExtensionsContext();
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);
  const [calculationResult, setCalculationResult] = useState(null);
  const searchInputRef = useRef();

  const data = [
    { title: "Customers", url: "/customers" },
    { title: "Vendors", url: "/vendors" },
    { title: "Products", url: "/products" },
    { title: "Invoice", url: "/invoice" },
    { title: "Settings", url: "/profile" },
    { title: "Dashboard", url: "/home" },
    { title: "Extensions", url: "/extensions" },
    { title: "Sales", url: "/sales" },
    { title: "Request Feature", url: "/requests" },
    { title: "Sheets", url: "/sheets" },

    // More links here...
  ];

  const isDarkModeExtensionInstalled =
    user &&
    extensions &&
    extensions.some(
      (extension) =>
        extension.extensionName === "Dark Mode" && extension.installed
    );

  const handleClick = () => {
    logout();
  };

  // New search logic:

  const filteredData = data.filter((link) =>
    link.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Input field event handlers.
  const [highlightIndex, dispatch] = useReducer(reducer, -1);

  const handleInputKeyDown = useCallback(
    (event) => {
      switch (event.key) {
        case "ArrowDown":
          dispatch({
            type: ActionTypes.ARROW_DOWN,
            payload: filteredData.length,
          });
          break;
        case "ArrowUp":
          dispatch({ type: ActionTypes.ARROW_UP });
          break;
        case "Enter":
          if (highlightIndex >= 0 && highlightIndex < filteredData.length) {
            dispatch({
              type: ActionTypes.ENTER,
              payload: filteredData[highlightIndex].url,
            });
          }
          break;
        default:
          break;
      }
    },
    [filteredData, highlightIndex]
  );

  //----------------------
  const handleInputChange = useCallback(
    (event) => {
      const inputValue = event.target.value;

      // Check if the input value is an arithmetic expression
      const isArithmetic = /^[\d+\-*/\s().]+$/.test(inputValue);

      if (isArithmetic) {
        try {
          // Try to calculate the expression
          const result = math.evaluate(inputValue); // use math.evaluate here
          // Update the calculation result
          setCalculationResult(result);
        } catch (error) {
          // If the expression is not valid, set the calculation result to null
          setCalculationResult(null);
        }
      } else {
        setCalculationResult(null);
      }

      setSearchTerm(inputValue);
      dispatch({ type: ActionTypes.RESET }); // reset highlight index when input changes

      // If the search term isn't empty, highlight the first result.
      if (inputValue !== "") {
        dispatch({
          type: ActionTypes.ARROW_DOWN,
          payload: filteredData.length,
        });
      }
    },
    [setSearchTerm, filteredData.length]
  );

  //------------------------------------//

  // 3 dot menu
  const handleMenu = () => {
    setOpen(!open);
  };

  const FNAH = () => {
    toast("This feature is not currently available.", {
      duration: 3000,
    });
  };

  const handleSetting = () => {
    window.location.href = "/profile";
  };

  const handleRequest = () => {
    window.location.href = "/requests";
  };

  const menuRef = useRef();

  // Function to handle click outside of the menu
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    // Add the click event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);
    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Search shortcut code//

  useEffect(() => {
    const keyHandler = (event) => {
      // Check if cmd (or ctrl for windows) + space was pressed
      if ((event.metaKey || event.altKey) && event.keyCode === 32) {
        // Prevent the default action (Spotlight search on Mac or Start menu on Windows)
        event.preventDefault();
        // Focus on the search input field
        searchInputRef.current.focus();
      }
    };

    window.addEventListener("keydown", keyHandler);

    // Don't forget to clean up the event listener
    return () => window.removeEventListener("keydown", keyHandler);
  }, []);

  //---------------------//

  return (
    <Component>
      <Toaster position='top-center' reverseOrder={false} />
      <Content>
        <Link to='/'>
          <Logo>
            <img src='../images/logo.svg' alt='logo'></img>
          </Logo>
        </Link>
        {user && (
          <Search>
            <div>
              <input
                type='text'
                placeholder='Search in Dashboard'
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                ref={searchInputRef}
              ></input>
              {searchTerm !== "" && (
                <SEARCHBOX>
                  {calculationResult !== null ? (
                    <div className='HSSCSS-not-selected'>
                      <ResultSpan>
                        <TbCalculator style={{ fontSize: "22px" }} /> ={" "}
                        {calculationResult}
                      </ResultSpan>
                    </div>
                  ) : (
                    filteredData.map((link, index) => {
                      const isSelected = index === highlightIndex;
                      const searchIndex = link.title
                        .toLowerCase()
                        .indexOf(searchTerm.toLowerCase());
                      const firstPart = link.title.substring(0, searchIndex);
                      const boldPart = link.title.substring(
                        searchIndex,
                        searchIndex + searchTerm.length
                      );
                      const lastPart = link.title.substring(
                        searchIndex + searchTerm.length
                      );

                      return (
                        <div>
                          <SDV></SDV>
                          <div
                            key={link.title}
                            className={
                              isSelected
                                ? "HSSCSS-selected"
                                : "HSSCSS-not-selected"
                            }
                          >
                            <a
                              className={isSelected ? "CINSCCSS" : "CICCSSS"}
                              href={link.url}
                            >
                              <span style={{ fontWeight: 600 }}>
                                {firstPart}
                              </span>
                              <span style={{ fontWeight: 400 }}>
                                {boldPart}
                              </span>
                              <span style={{ fontWeight: 600 }}>
                                {lastPart}
                              </span>
                            </a>
                          </div>
                        </div>
                      );
                    })
                  )}
                </SEARCHBOX>
              )}
            </div>
            <DashboardSearch />
            <DashboardSearchIcons>
              <LuCommand />
              <LuSpace />
            </DashboardSearchIcons>
          </Search>
        )}
        <Nav>
          {isDarkModeExtensionInstalled && (
            <div className='DMDIV'>
              <DarkModeSwitch />
            </div>
          )}

          {user && (
            <Navlistwrap className='HeaderCompany'>
              <>
                <NotificationBell>
                  <LuBell style={{ fontSize: "20px" }} />
                </NotificationBell>
                <PersonOutlineIcondiv>
                  <PersonOutlineIcon />
                </PersonOutlineIcondiv>
                <span className='HeaderCompanyName'>{user.company}</span>

                <THREEDOTMENU>
                  <button onClick={handleMenu}>
                    <THREEDOTMENUBUTTON />
                  </button>
                  {open && (
                    <THREEDOTMENULIST ref={menuRef}>
                      <THREEDOTMENULISTITEMS onClick={handleSetting}>
                        <AiFillSetting size={20} />
                        <span>{""} Settings</span>
                      </THREEDOTMENULISTITEMS>
                      <THREEDOTMENULISTITEMS onClick={FNAH}>
                        <BiHelpCircle size={20} />{" "}
                        <span>{""} Help & Support</span>
                      </THREEDOTMENULISTITEMS>
                      <THREEDOTMENULISTITEMS onClick={handleRequest}>
                        <TbForms size={20} />
                        <span>{""} Request A Feature</span>
                      </THREEDOTMENULISTITEMS>
                      <THREEDOTMENULISTITEMS onClick={handleClick}>
                        <HiOutlineLogout size={20} />
                        <span>{""} Logout</span>
                      </THREEDOTMENULISTITEMS>
                    </THREEDOTMENULIST>
                  )}
                </THREEDOTMENU>
              </>
            </Navlistwrap>
          )}
        </Nav>
      </Content>
    </Component>
  );
};

const Component = styled.div`
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  left: 0;
  padding: 0 24px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 100;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  min-height: 100%;
  max-width: 1128px;
  @media (max-width: 1366px) {
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const Logo = styled.span`
  margin: 8px;
  font-size: 0;
  & img {
    width: 134px;
    height: 50px;
    margin-left: 0;
  }
`;

const Search = styled.div`
  display: flex;
  opacity: 2;
  flex-grow: 1;
  position: relative;
  & > div {
    max-width: 280px;
    border-radius: 8px;
    & > input {
      /* border: 1px solid black; */
      border: none;
      background-color: white;
      color: rgba(0, 0, 0, 0.9);
      width: 218px;
      padding: 0 8px 0 40px;
      line-height: 1.75;
      font-weight: 400;
      height: 40px;
      width: 320px;
      border-color: #dce6f1;
      vertical-align: text-top;
      border-radius: 8px;
      outline: none;
      z-index: 998;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

      &:focus {
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border: none;
        /* outline: 2px solid rgba(0, 100, 255, 255); */
      }
    }
  }
`;

const DashboardSearch = styled(TbSearch)`
  width: 40px;
  font-size: 22px;
  position: absolute;
  z-index: 1;
  top: 9px;
  left: 3px;
  border-radius: 0 2px 2px 0;
  margin: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashboardSearchIcons = styled.div`
  display: flex;
  background-color: white;
  border-radius: 8px;
  column-gap: 4px;
  justify-content: center;
  align-items: center;
  height: 34px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  left: 312px;
  top: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
`;

const Nav = styled.nav`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1366px) {
    position: fixed;
    right: -800px;
    width: 100%;
  }
  @media (max-width: 1280px) {
    position: fixed;
    right: -800px;
    width: 100%;
  }
  @media (max-width: 768px) {
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;
    width: 100%;
  }
`;

const Navlistwrap = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;

  .active {
    span:after {
      content: "";
      transform: scaleX(1);
      border-bottom: 2px solid var(--white, #fff);
      bottom: 0;
      left: 0;
      position: absolute;
      transition: transform 0.2s ease-in-out;
      width: 100%;
      border-color: rgba(0, 0, 0, 0.9);
    }
  }
`;

const SEARCHBOX = styled.div`
  position: absolute;
  background-color: white;
  text-decoration: none;
  width: 318px;
  /* border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px; */
  border-radius: 8px;
  margin-top: 10px;
  padding: 10px;
  padding-left: 40px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  top: 38px;
  & div {
    margin-bottom: 10px;
  }
  & a {
    text-decoration: none;
    color: black;
    font-size: 12px;
    font-family: "Open Sans";
  }
`;

const PersonOutlineIcondiv = styled.div`
  background-color: white;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 34px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
`;

const THREEDOTMENU = styled.div`
  /* background-color: #eef3f8; */
  background-color: white;
  border: none;
  & button {
    border: none;
    /* background-color: #eef3f8; */
    background-color: white;
  }
  & div {
    padding: 10px;
  }
  & span {
    justify-content: center;
    align-items: center;
    font-family: "Open Sans";
    padding-bottom: 20px;
    position: relative;
  }
`;

const THREEDOTMENUBUTTON = styled(MoreHorizIcon)`
  border: none;
  border-radius: 16px;
  /* background-color: #eef3f8; */
  background-color: white;
`;

const THREEDOTMENULIST = styled.div`
  position: absolute;
  background-color: white;
  width: 180px;
  height: 234px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
`;

const THREEDOTMENULISTITEMS = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  &:last-child {
    color: red;
  }
  &:hover {
    cursor: pointer;
    background-color: #eef3f8;
    border-radius: 8px;
    height: 20%;
  }
`;

const ResultSpan = styled.div`
  font-family: "Open Sans";
  font-family: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SDV = styled.div`
  font-size: 9px;
  color: rgba(0, 0, 0, 0.9);
  font-family: "Open Sans";
  font-weight: 600;
`;

const NotificationBell = styled.div`
  border-right: 1px solid lightgrey;
  padding-right: 8px;
  display: flex;
  justify-content: center;
`;

export default Header;
