import { useState, useEffect } from "react";
import { useCustomersContext } from "../../hooks/useCustomersContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast from "react-hot-toast";
import styled from "styled-components";
import axios from "axios";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const CustomerEditForm = ({ customer, closeForm }) => {
  const { dispatch } = useCustomersContext();
  const { user } = useAuthContext();
  const [firstName, setFirstName] = useState(
    customer ? customer.firstName : ""
  );
  const [lastName, setLastName] = useState(customer ? customer.lastName : "");
  const [company, setCompany] = useState(customer ? customer.company : "");
  const [phoneNumber, setPhoneNumber] = useState(
    customer ? customer.phoneNumber : ""
  );
  const [email, setEmail] = useState(customer ? customer.email : "");
  const [address, setAddress] = useState(customer ? customer.address : "");
  const [error, setError] = useState(null);
  const [emptyFields, setEmptyFields] = useState([]);
  const [data] = useState(null);

  const handleCustomerToast = () => {
    toast.success("Customer Updated Successfully");
  };

  useEffect(() => {
    if (error === null && emptyFields.length === 0 && data !== null) {
      dispatch({ type: "UPDATE_CUSTOMER", payload: data });
      handleCustomerToast();
      closeForm();
    }
  }, [error, emptyFields, data, dispatch, closeForm]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedCustomer = {
      firstName,
      lastName,
      company,
      phoneNumber,
      email,
      address,
    };

    try {
      const response = await axios.patch(
        `${API_URL}/api/customers/${customer._id}`,
        updatedCustomer,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status >= 200 && response.status < 300) {
        setError(null);
        setEmptyFields([]);

        // Refetch the customers after successful update
        const updatedCustomersResponse = await axios.get(
          `${API_URL}/api/customers`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        dispatch({
          type: "SET_CUSTOMERS",
          payload: updatedCustomersResponse.data,
        });

        handleCustomerToast();
        closeForm();
      } else {
        setError(response.data.error);
        setEmptyFields(
          Array.isArray(response.data.emptyFields)
            ? response.data.emptyFields
            : []
        );
      }
    } catch (error) {
      console.error("Error updating customer", error);
      toast.error("Failed to update customer");
    }
  };

  const isFormValid =
    firstName && lastName && company && phoneNumber && email && address;

  return (
    <Container>
      <FormContainer onSubmit={handleSubmit}>
        <h3 className='ACCF'>Add a New Customer</h3>

        <FormGroup>
          <label>First Name:</label>
          <input
            type='text'
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            autoFocus
            className={emptyFields.includes("firstName") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Last Name:</label>
          <input
            type='text'
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            className={emptyFields.includes("lastName") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Company:</label>
          <input
            type='text'
            onChange={(e) => setCompany(e.target.value)}
            value={company}
            className={emptyFields.includes("company") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Phone Number:</label>
          <input
            type='number'
            onChange={(e) => setPhoneNumber(e.target.value)}
            value={phoneNumber}
            className={emptyFields.includes("phoneNumber") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Email:</label>
          <input
            type='text'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            className={emptyFields.includes("email") ? "error" : ""}
          />
        </FormGroup>

        <FormGroup>
          <label>Address:</label>
          <input
            type='text'
            onChange={(e) => setAddress(e.target.value)}
            value={address}
            className={emptyFields.includes("address") ? "error" : ""}
          />
        </FormGroup>

        <ButtonContainer>
          <button onClick={handleSubmit} disabled={!isFormValid}>
            Update Customer
          </button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 320px;
  background-color: #fff;
  border-radius: 4px;
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  padding: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-family: "Open Sans";
    color: #303030;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: lightgrey;
    outline: none;
    font-size: 14px;
    position: relative;
    font-family: "Open Sans";
    flex: 1;
    margin-left: 10px;
  }

  .error {
    border-color: red;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  margin-top: 10px;
  width: 360px;

  button {
    padding: 10px 16px;
    width: 160px;
    background-color: rgba(0, 100, 225, 255);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
  button:disabled {
    padding: 10px 16px;
    width: 160px;
    background-color: darkgray;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); */
    color: white;
    cursor: not-allowed;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
`;

export default CustomerEditForm;
