import React, { useState } from "react";
import styled from "styled-components";
import { useAuthContext } from "../../hooks/useAuthContext";
import FolderDeleteIcon from "@mui/icons-material/FolderDelete";

const DangerZone = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { user } = useAuthContext();

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setInputValue("");
  };

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <Container>
      <h2>Danger Zone</h2>
      <DivHDDA>Delete this account</DivHDDA>
      <DivMSGDA>
        WARNING: Once you delete your account, All data, including personal
        info, history, and subscriptions, will be permanently removed and cannot
        be recovered.{" "}
      </DivMSGDA>
      <DivDA>
        <ButtonDA onClick={handleOpen}>Delete this account</ButtonDA>
      </DivDA>
      {isOpen && (
        <Backdrop>
          <DeleteModal>
            <h1>Delete {user.company} profile</h1>
            <DELpopdiv>
              <FolderDeleteIcon />
              <p>I have read and understand these effects</p>
            </DELpopdiv>
            <p>To confirm, type "permanently delete" in the box below</p>
            <input type='text' value={inputValue} onChange={handleInput} />
            <div>
              <button onClick={handleClose}>Cancel</button>
              <button
                disabled={inputValue !== "permanently delete"}
                onClick={handleClose}
              >
                Delete
              </button>
            </div>
          </DeleteModal>
        </Backdrop>
      )}
    </Container>
  );
};

const Container = styled.div`
  border: 0.5px solid;
  border-radius: 4px;
  border-color: red;
  width: 552px;
  height: 180px;
  padding: 10px;

  & h2 {
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
  }
  @media (max-width: 1366px) {
    border: 0.5px solid;
    border-radius: 4px;
    border-color: red;
    width: 532px;
    height: 110px;
    padding: 5px;

    & h2 {
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  @media (max-width: 1280px) {
    border: 0.5px solid;
    border-radius: 4px;
    border-color: red;
    width: 512px;
    height: 110px;
    padding: 5px;

    & h2 {
      font-family: "Open Sans";
      font-size: 12px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

const DivHDDA = styled.div`
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  padding-top: 26px;
  @media (max-width: 1366px) {
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    padding-top: 8px;
  }
  @media (max-width: 1280px) {
    font-family: "Open Sans";
    font-size: 13px;
    font-weight: 600;
    padding-top: 8px;
  }
`;

const DivMSGDA = styled.div`
  font-family: "Open Sans";
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  color: darkgray;
  @media (max-width: 1366px) {
    font-family: "Open Sans";
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 10px;
    color: darkgray;
  }
  @media (max-width: 1280px) {
    font-family: "Open Sans";
    padding-top: 6px;
    padding-bottom: 6px;
    font-size: 10px;
    color: darkgray;
  }
`;

const DivDA = styled.div`
  display: flex;
  justify-content: right;
  align-items: flex-end;
  padding-top: 10px;
  @media (max-width: 1366px) {
    padding-top: 0px;
    position: relative;
    top: -8px;
  }
  @media (max-width: 1280px) {
    padding-top: 0px;
    position: relative;
    top: -8px;
  }
`;

const ButtonDA = styled.button`
  padding: 10px;
  border: none;
  border-radius: 8px;
  color: red;
  cursor: pointer;
  font-family: "Open Sans";
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 192px;

  &:hover {
    background-color: red;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1366px) {
    padding: 8px;
    width: 182px;

  }

  @media (max-width: 1280px) {
    padding: 8px;
    width: 182px;

  }
`;

const DeleteModal = styled.div`
  position: fixed;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-family: "Open Sans";
  font-size: 15px;

  & > input {
    display: flex;
    border-radius: 4px;
    border: 0.4px solid;
    margin-top: 10px;
    padding: 6px;
    margin-bottom: 10px;
  }
  & div {
    display: flex;

    & > button {
      border: none;
      width: 200px;
      border-radius: 4px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      padding: 10px;
      background-color: white;
      cursor: pointer;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        background-color: red;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
        color: white;
        cursor: pointer;
      }

      &:disabled {
        background-color: lightgrey;
        cursor: not-allowed;
      }
    }
  }
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`;

const DELpopdiv = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: lightgray;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px;
`;

export default DangerZone;
