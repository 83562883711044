import { ExtensionsContext } from "../context/extensionContext";
import { useContext } from "react";

export const useExtensionsContext = () => {
  const context = useContext(ExtensionsContext);
  if (!context) {
    throw Error(
      "useExtensionContext must be used inside an ExtensionsContextProvider"
    );
  }
  return context;
};