import React from "react";
import axios from "axios";
import { format, parseISO } from "date-fns";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Cell,
} from "recharts";
import { useInvoicesContext } from "../../hooks/useInvoicesContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import styled from "styled-components";
import { startOfMonth, endOfMonth, subMonths } from "date-fns";
import { LuArrowUpRight } from "react-icons/lu";
import { LuArrowUpLeft } from "react-icons/lu";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const COLORS = ["#3B82F6"];

const SalesGraph = () => {
  const { invoices, invoicedispatch } = useInvoicesContext();
  const { user } = useAuthContext();
  const [filteredInvoices, setFilteredInvoices] = React.useState([]);

  React.useEffect(() => {
    const fetchSales = async () => {
      if (user) {
        try {
          const response = await axios.get(`${API_URL}/api/sales`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });
          invoicedispatch({ type: "SET_INVOICES", payload: response.data });
        } catch (error) {
          console.error("There was an error!", error);
        }
      }
    };
    fetchSales();
  }, [invoicedispatch, user]);

  React.useEffect(() => {
    if (invoices) {
      const aggregatedInvoices = invoices.reduce((acc, invoice) => {
        const invoiceMonth = format(parseISO(invoice.date), "MMM");
        if (!acc[invoiceMonth]) {
          acc[invoiceMonth] = {
            name: invoiceMonth,
            sales: invoice.grandTotal,
          };
        } else {
          acc[invoiceMonth].sales += invoice.grandTotal;
        }
        return acc;
      }, {});
      setFilteredInvoices(Object.values(aggregatedInvoices));
    }
  }, [invoices]);

  ////////////////////////////////////////////

  const [startOfPrevMonth, endOfPrevMonth] = React.useMemo(() => {
    const prevMonth = subMonths(new Date(), 1);
    return [startOfMonth(prevMonth), endOfMonth(prevMonth)];
  }, []);

  const previousTotal = React.useMemo(() => {
    if (invoices) {
      return invoices.reduce((total, invoice) => {
        const invoiceDate = parseISO(invoice.date);
        if (invoiceDate >= startOfPrevMonth && invoiceDate <= endOfPrevMonth) {
          total += invoice.grandTotal;
        }
        return total;
      }, 0);
    }
    return 0;
  }, [invoices, startOfPrevMonth, endOfPrevMonth]);

  const totalGrandTotal = React.useMemo(() => {
    return invoices
      ? invoices.reduce((sum, invoice) => sum + invoice.grandTotal, 0)
      : 0;
  }, [invoices]);

  const percentageChange = React.useMemo(() => {
    const difference = totalGrandTotal - previousTotal;
    return previousTotal ? ((difference / previousTotal) * 100).toFixed(2) : 0;
  }, [totalGrandTotal, previousTotal]);

  ////////////////////////////////////////////////////

  return (
    <SalesGraphMain>
      <SalesTitle>Sales view</SalesTitle>
      <SaleTitleValues>
        <p>₹{totalGrandTotal}</p>
        <p style={{ color: percentageChange >= 0 ? "green" : "red" }}>
          {percentageChange}%
          {percentageChange >= 0 ? (
            <LuArrowUpRight style={{ fontSize: "13px", marginLeft: "1px" }} />
          ) : (
            <LuArrowUpLeft />
          )}
        </p>
      </SaleTitleValues>
      <ResponsiveContainer>
        <BarChart data={filteredInvoices} barGap={8} barCategoryGap='20%'>
          <XAxis
            dataKey='name'
            axisLine={false}
            tickLine={false}
            tick={{
              fill: "#8A8A8A",
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Helvetica Neue",
            }}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={(value) => `₹${value}`}
            tick={{
              fill: "#8A8A8A",
              fontSize: 12,
              fontWeight: 500,
              fontFamily: "Helvetica Neue",
            }}
          />
          <Tooltip
            cursor={false}
            contentStyle={{
              background: "rgba(38, 38, 38, 0.8)",
              border: "none",
              borderRadius: "8px",
              color: "#fff",
              fontSize: "12px",
              lineHeight: "16px",
              padding: "10px",
            }}
            labelStyle={{ fontSize: "12px", fontWeight: 500 }}
            formatter={(value) => [`Sales: $${value}`]}
            labelFormatter={(label) => `Month: ${label}`}
          />
          <Bar
            dataKey='sales'
            fill='#3B82F6'
            barSize={20}
            radius={[10, 10, 0, 0]}
          >
            {filteredInvoices.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </SalesGraphMain>
  );
};

const SalesGraphMain = styled.div`
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  background: white; // Dark background color
  border-radius: 15px; // Rounded corners
`;

const SalesTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: "Open Sans";
  font-size: 12px;
  padding-bottom: 10px;
  font-weight: 600;
  color: #a1a7b3; // Lighter color for text
`;

const SaleTitleValues = styled.div`
  display: flex;
  column-gap: 10px;
  padding-bottom: 30px;
  align-items: center;
  color: #a1a7b3; // Lighter color for text

  p {
    margin: 0;
    font-size: 24px; // Larger text for the values
    font-weight: 600;
  }

  p:last-child {
    font-size: 12px; // Smaller text for the percentage
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export default SalesGraph;
