import styled from "styled-components";

export const PageContainer = styled.div`
  grid-area: main;
  width: 824px;
  height: 904px;
  max-width: auto;
  page-break-after: always;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  border-radius: 4px;
  overflow: auto;
  @media (max-width: 1366px) {
    width: 100%;
    height: 632px;
  }

  @media (max-width: 1280px) {
    width: 100%;
    height: 632px;
  }

  @media print {
    background-color: white;
  }
`;

export const TopFlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 6px;

  @media (max-width: 1366px) {
    margin-bottom: 3px;
  }

  @media (max-width: 1280px) {
    margin-bottom: 3px;
  }
`;

export const InvoiceFormContainer = styled.div`
  padding: 2cm;
  position: relative;
  @media (max-width: 1366px) {
    padding: 1cm;
  }
  @media (max-width: 1280px) {
    padding: 1cm;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 1cm;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid;
  border-color: lightgray;
  padding-top: 10px;

  @media (max-width: 1366px) {
    margin-bottom: 0.5cm;
    padding-top: 5px;
    border-top: 0.5px solid;
    border-color: lightgray;
  }

  @media (max-width: 1280px) {
    margin-bottom: 0.5cm;
    padding-top: 5px;
    border-top: 0.5px solid;
    border-color: lightgray;
  }
`;

export const BilledToContainer = styled.div`
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px;
  width: 316px;
  padding: 8px;
  font-family: "Open Sans";

  @media (max-width: 1366px) {
    border: 0.5px solid;
    border-color: lightgray;
    border-radius: 6px;
    width: 262px;
    padding: 4px;
    font-family: "Open Sans";
  }

  @media (max-width: 1280px) {
    border: 0.5px solid;
    border-color: lightgray;
    border-radius: 6px;
    width: 242px;
    padding: 4px;
    font-family: "Open Sans";
  }

  @media print {
    width: 298px;
  }
`;

export const StyledEditIcon = styled.div``;

export const FromContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  border: 1px solid;
  border-color: lightgray;
  border-radius: 6px;
  width: 316px;
  padding: 8px;
  font-family: "Open Sans";

  @media (max-width: 1366px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 0.5px solid;
    border-color: lightgray;
    border-radius: 6px;
    width: 262px;
    padding: 4px;
    font-family: "Open Sans";
  }

  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    align-items: left;
    border: 0.5px solid;
    border-color: lightgray;
    border-radius: 6px;
    width: 242px;
    padding: 4px;
    font-family: "Open Sans";
  }

  @media print {
    width: 298px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;

  @media print {
    text-align: right;
    position: relative;
    right: -98px;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  @media (max-width: 1366px) {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  @media (max-width: 1280px) {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
`;

export const Input = styled.input`
  width: 80%;
  padding: 0.5rem;
  font-size: 14px;
  @media (max-width: 1366px) {
    width: 80%;
    padding: 0.25rem;
    font-size: 12px;
  }
  @media (max-width: 1280px) {
    width: 80%;
    padding: 0.25rem;
    font-size: 12px;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1.5cm;
  @media (max-width: 1366px) {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.75cm;
  }
  @media (max-width: 1280px) {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 0.75cm;
  }
`;

export const TableHead = styled.thead``;

export const TableHeaderCell = styled.th`
  padding: 0.5rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
  background-color: white;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;

  @media (max-width: 1366px) {
    padding: 0.25rem;
    text-align: left;
    border-bottom: 0.5px solid #ddd;
    background-color: white;
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 600;
  }

  @media (max-width: 1280px) {
    padding: 0.25rem;
    text-align: left;
    border-bottom: 0.5px solid #ddd;
    background-color: white;
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 600;
  }

  @media print {
    background-color: transparent;
  }
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr``;

export const TableCell = styled.td`
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;

  @media (max-width: 1366px) {
    padding: 0.25rem;
    border-bottom: 0.5px solid #ddd;
  }

  @media (max-width: 1280px) {
    padding: 0.25rem;
    border-bottom: 0.5px solid #ddd;
  }
`;

export const Button = styled.button`
  padding: 0.3rem 0.6rem;
  font-size: 12px;
  opacity: 0;
  transition: opacity 0.3s ease;
  @media (max-width: 1366px) {
    padding: 0.15rem 0.3rem;
    font-size: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  @media (max-width: 1280px) {
    padding: 0.15rem 0.3rem;
    font-size: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
`;

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  svg {
    font-size: 20px;
  }
  @media (max-width: 1366px) {
    display: flex;
    align-items: center;
    margin-right: 0.25rem;

    & > svg {
      font-size: 14px;
    }
  }
  @media (max-width: 1280px) {
    display: flex;
    align-items: center;
    margin-right: 0.25rem;

    & > svg {
      font-size: 14px;
    }
  }
`;

export const HorizontalLine = styled.hr`
  margin: 1.5cm 0;
  border: none;
  border-top: 1px solid #ddd;
  @media (max-width: 1366px) {
    margin: 0.75cm 0;
    border: none;
    border-top: 0.5px solid #ddd;
  }
  @media (max-width: 1280px) {
    margin: 0.75cm 0;
    border: none;
    border-top: 0.5px solid #ddd;
  }
`;

export const GrandTotalSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1.5cm;
  @media (max-width: 1366px) {
    margin-bottom: 0.75cm;
  }
  @media (max-width: 1280px) {
    margin-bottom: 0.75cm;
  }
`;

export const GrandTotalLabel = styled.span`
  font-weight: bold;
  margin-right: 1rem;
  @media (max-width: 1366px) {
    margin-right: 0.5rem;
  }
  @media (max-width: 1280px) {
    margin-right: 0.5rem;
  }
`;

export const GrandTotalValue = styled.span`
  font-weight: bold;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5cm;
  @media (max-width: 1366px) {
    margin-top: 0.75cm;
  }
  @media (max-width: 1280px) {
    margin-top: 0.75cm;
  }
`;

export const AddItemButton = styled(Button)`
  padding: 0.3rem 0.6rem;
  font-size: 12px;
  background-color: white;
  color: black;
  font-family: "Open Sans";
  border: none;
  border-radius: 6px;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex; // Add this line to layout the icon and text horizontally
  align-items: center; // Add this line to vertically center the icon and text

  &:hover {
    background-color: lightgrey;
    color: black;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 1366px) {
    padding: 0.15rem 0.3rem;
    font-size: 10px;
    background-color: white;
    color: black;
    font-family: "Open Sans";
    border: none;
    border-radius: 6px;
    cursor: pointer;
    opacity: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex; // Add this line to layout the icon and text horizontally
    align-items: center; //
  }

  @media (max-width: 1280px) {
    padding: 0.15rem 0.3rem;
    font-size: 10px;
    background-color: white;
    color: black;
    font-family: "Open Sans";
    border: none;
    border-radius: 6px;
    cursor: pointer;
    opacity: 1;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex; // Add this line to layout the icon and text horizontally
    align-items: center; //
  }

  @media print {
    display: none;
  }
`;

export const ButtonsContainer2 = styled.div`
  display: flex;
  justify-content: flex-end;

  @media print {
    display: none;
  }
`;

export const PrintButtonMain = styled.button`
  display: flex;
  justify-content: center;
  width: 100px;
  border-radius: 6px;
  margin: 2px;
  border: none;
  font-family: "Open Sans";
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 100, 225, 255);
  color: white;
  align-items: center;
  padding: 8px;
  @media (max-width: 1366px) {
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    margin: 1px;
    border: none;
    font-family: "Open Sans";
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 100, 225, 255);
    color: white;
    align-items: center;
    padding: 4px;
  }

  @media (max-width: 1280px) {
    display: flex;
    justify-content: center;
    width: 100px;
    border-radius: 6px;
    margin: 1px;
    border: none;
    font-family: "Open Sans";
    font-size: 12px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 100, 225, 255);
    color: white;
    align-items: center;
    padding: 4px;
  }
`;

