import ReceiptIcon from '@mui/icons-material/Receipt';
import React from "react";
import styled from "styled-components";

const GSTInvoiceExtension = ({ onInstall, onUninstall, isInstalled, extensionId }) => {
    const extension = {
      extensionName: "GST Invoice",
      description: "The Dark Mode Extension is a powerful tool designed to transform your web browsing experience by enabling dark mode.",
      installed: isInstalled || false,
    };
  
    return (
      <ExtensionDivContainer>
        <ReceiptIcon />
        <ExtensionName>{extension.extensionName}</ExtensionName>
        <Description>{extension.description}</Description>
        {isInstalled ? (
          <button className="UNEXCSS" onClick={() => onUninstall(extensionId)}>Uninstall</button>
        ) : (
          <button className="INEXCSS" onClick={() => onInstall(extension)}>Install</button>
        )}
      </ExtensionDivContainer>
    );
};
  

const ExtensionDivContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ExtensionName = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-family: "Open Sans";
  padding: 10px;
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans";
  padding: 10px;
  text-align: justify;
`;

export default GSTInvoiceExtension;