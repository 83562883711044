import styled from "styled-components";
import "./landingpage.css";
import { Link } from "react-router-dom";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;

const LandingPage = (props) => {
  return (
    <>
      <GlobalStyle />

      <Container>
        <Nav>
          <Link to='/'>
            <img src='/images/logo.svg' alt='' />
          </Link>
          <div>
            <Link to='/signup' className='GetStartedLP'>
              <Join>Get Started</Join>
            </Link>
            <Link to='/login' className='SignInLP'>
              <SignIn>Sign In</SignIn>
            </Link>
          </div>
        </Nav>
        <Section>
          <Hero>
            <h1>Welcome to accountloop studio engine</h1>
            <img src='/images/NewWall.png' alt='' />
          </Hero>
          <NoName>
            <Link to='/signup' className='ExploreLP'>
              <Explore>Explore Platform</Explore>
            </Link>
          </NoName>
        </Section>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 0px;
  background-color: white;
`;

const Nav = styled.nav`
  max-width: 1128px;
  margin: auto;
  padding: 12px 0 16px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-wrap: nowrap;

  & > a {
    width: 135px;
    height: 34px;
    @media (max-width: 768px) {
      padding: 0 5px;
      & img {
        width: 155px;
        height: 35px;
      }
    }
  }
`;

const Join = styled.a`
  font-size: 16px;
  padding: 10px 12px;
  text-decoration: none;
  border-radius: 5px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 12px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
  }
`;

const SignIn = styled.a`
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 5px;
  transition-duration: 167ms;
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  padding: 10px 24px;
  text-align: center;
  background-color: rgba(0, 100, 225, 255);
  &:hover {
    background-color: #2b7cff;
    color: white;
    text-decoration: none;
  }
`;

const Section = styled.section`
  align-content: start;
  display: flex;
  min-height: 700px;
  padding-bottom: 138px;
  padding-top: 40px;
  padding: 60px 0;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1128px;
  align-items: center;
  margin: auto;
  @media (max-width: 768px) {
    margin: auto;
    min-height: 0px;
  }
`;

const Hero = styled.div`
  width: 100%;
  h1 {
    padding-bottom: 0;
    width: 55%;
    font-size: 50px;
    color: darkgray;
    font-weight: 200;
    font-family: "Open Sans";
    line-height: 65px;
    @media (max-width: 768px) {
      text-align: center;
      font-size: 20px;
      width: 100%;
      line-height: 2;
    }
  }

  img {
    width: 700px;
    height: 670px;
    position: absolute;
    bottom: 0px;
    right: -150px;
    @media (max-width: 768px) {
      top: 230px;
      width: initial;
      position: initial;
      height: initial;
    }
  }
`;

const NoName = styled.div`
  margin-top: 100px;
  width: 408px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const Explore = styled.button`
  display: flex;
  justify-content: center;
  background-color: white;
  align-items: center;
  height: 56px;
  border: none;
  text-decoration: none;
  width: 100%;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  vertical-align: middle;
  z-index: 0;
  transition-duration: 167ms;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.6);
  &:hover {
    background-color: rgba(207, 207, 207, 0.25);
    color: black;
  }
`;

export default LandingPage;
