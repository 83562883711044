import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login/login";
import Signup from "./pages/Signup/signup";
import LandingPage from "./pages/landing page/landingpage";
import Dashboard from "./Dashboard/Dashboard";
import Header from "./components/Header";
import Footer from "./components/Footer";
import CustDashboard from "./Dashboard/CustomerDashboard";
import ProdDashboard from "./Dashboard/ProductDashboard";
import InvoiceDashboard from "./Dashboard/InvoiceDashboard";
import { useAuthContext } from "./hooks/useAuthContext";
import ProfileDashboard from "./Dashboard/ProfileDashboard";
import SignupSuccess from "./pages/Signup/signupSuccess";
import ExtensionsDashboard from "./Dashboard/ExtensionsDashboard";
import VendorDashboard from "./Dashboard/VendorDashboard";
import SaleDashboard from "./Dashboard/SaleDashboard";
import ReqDashboard from "./Dashboard/RequestDashboard";
import Forgetpass from "./pages/Login/forgetpass";
import PasswordChangeSuccess from "./pages/Login/passwordChangeSuccess";

function App() {
  const { loading, user } = useAuthContext();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route
            path='/'
            element={
              !user ? (
                <>
                  <LandingPage />
                  <Footer />
                </>
              ) : (
                <Navigate to='/home' />
              )
            }
          />
          <Route
            path='/login'
            element={
              !user ? (
                <>
                  <Header />

                  <Login />
                </>
              ) : (
                <Navigate to='/home' />
              )
            }
          />
          <Route
            path='/forgotpassword'
            element={
              !user ? (
                <>
                  <Header />
                  <Forgetpass />
                </>
              ) : (
                <Navigate to='/home' />
              )
            }
          />
          <Route
            path='/signup'
            element={
              !user ? (
                <>
                  <Header />

                  <Signup />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/success'
            element={
              !user ? (
                <>
                  <Header />

                  <SignupSuccess />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/rpsuccess'
            element={
              !user ? (
                <>
                  <Header />

                  <PasswordChangeSuccess />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/home'
            element={
              user ? (
                <>
                  <Header />
                  <Dashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/customers'
            element={
              user ? (
                <>
                  <Header />

                  <CustDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/vendors'
            element={
              user ? (
                <>
                  <Header />

                  <VendorDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/sales'
            element={
              user ? (
                <>
                  <Header />

                  <SaleDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/products'
            element={
              user ? (
                <>
                  <Header />

                  <ProdDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/invoice'
            element={
              user ? (
                <>
                  <Header />

                  <InvoiceDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/profile'
            element={
              user ? (
                <>
                  <Header />

                  <ProfileDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/extensions'
            element={
              user ? (
                <>
                  <Header />

                  <ExtensionsDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
          <Route
            path='/requests'
            element={
              user ? (
                <>
                  <Header />

                  <ReqDashboard />
                </>
              ) : (
                <Navigate to='/' />
              )
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
