import { useState, useEffect, useRef } from "react"; // Add useRef here
import { useSaleEntriesContext } from "../../hooks/useSaleEntriesContext";
import { useAuthContext } from "../../hooks/useAuthContext";
import toast from "react-hot-toast";
import styled from "styled-components";
import axios from "axios";
import { useCombobox } from "downshift";
import { RiArrowDropDownLine } from "react-icons/ri";

const API_URL = process.env.REACT_APP_ACCOUNTLOOP_API_URL;

const SaleForm = ({ sales }) => {
  const { saleEntrydispatch } = useSaleEntriesContext();
  const { user } = useAuthContext();
  const [invoice, setInvoice] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const [debit, setDebit] = useState("");
  const [error, setError] = useState(null);

  ///////////////////////////////////////////
  const [inputItems, setInputItems] = useState(sales);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputItems(
      sales.filter((item) =>
        item.invoiceNumber.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  }, [inputValue, sales]);

  const invoiceInputRef = useRef();
  const descriptionInputRef = useRef();

  // ...

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset,
  } = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setInvoice(selectedItem._id);
        setCompany(selectedItem.customer.company);
        descriptionInputRef.current.focus();
      } else {
        setInvoice("");
        setCompany("");
      }
    },
    itemToString: (item) => (item ? item.invoiceNumber : ""),
    inputId: "invoice-input",
  });

  /////////////////////////////////////////////

  ////////////////////////////////////

  const handleSubmit = (e) => {
    e.preventDefault();

    return new Promise(async (resolve, reject) => {
      // wrap everything inside a new promise

      if (!user) {
        setError("You must be logged in");
        reject(); // Promise is rejected if there's an error
        return;
      }

      if (!invoice || !debit) {
        setError("Invoice and debit are required.");
        reject();
        return;
      }

      const saleEntryData = {
        invoices: invoice,
        description,
        debit,
      };

      try {
        const response = await axios.post(
          `${API_URL}/api/saleEntry`,
          saleEntryData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        const data = response.data;

        if (response.status >= 200 && response.status < 300) {
          setInvoice("");
          setCompany("");
          setDescription("");
          setDebit("");
          setInputValue("");
          reset();
          setError(null);
          saleEntrydispatch({ type: "CREATE_SALEENTRY", payload: data });
          invoiceInputRef.current.focus();
          resolve(); // Promise is resolved if everything is okay
        } else {
          setError(data.error);
          reject();
        }
      } catch (error) {
        console.error("Error adding sale entry", error);
        reject();
      }
    });
  };

  ////////////////////////////////////////
  const toastytoast = () => {
    return (e) => {
      e.preventDefault();
      toast.promise(handleSubmit(e), {
        loading: "Saving...",
        success: <b>New Sale Entry Added</b>,
        error: <b>Failed to add sale.</b>,
      });
    };
  };

  /////////////////////////////////////////

  const isFormValid = invoice && debit;

  return (
    <Container>
      <FormContainer onSubmit={toastytoast()}>
        <h3 className='ACCF'>Add a New Sale Entry</h3>
        <FormGroup>
          <label {...getLabelProps()}>Invoice:</label>
          <div style={{ position: "relative" }}>
            {/* <input {...getInputProps()} ref={invoiceInputRef} autoFocus /> */}
            <input
              {...getInputProps({
                ref: (node) => {
                  invoiceInputRef.current = node;
                  getInputProps().ref(node);
                },
              })}
              autoFocus
            />

            <InvoiceButton
              type='button'
              {...getToggleButtonProps({
                onClick: (event) => {
                  event.stopPropagation();
                },
              })}
            >
              <RiArrowDropDownLine style={{ fontSize: "22px" }} />
            </InvoiceButton>
          </div>
          <SearchResults>
            <DropDownList {...getMenuProps()}>
              {isOpen &&
                sales
                  .filter((item) =>
                    item.invoiceNumber
                      .toLowerCase()
                      .includes(getInputProps().value.toLowerCase())
                  )
                  .map((item, index) => (
                    <SelectedList
                      highlighted={highlightedIndex === index}
                      key={`${item}${index}`}
                      {...getItemProps({ item, index })}
                    >
                      {item.invoiceNumber}
                    </SelectedList>
                  ))}
            </DropDownList>
          </SearchResults>
        </FormGroup>

        <FormGroup>
          <label>Company:</label>
          <input
            type='text'
            onChange={(e) => setCompany(e.target.value)}
            value={company}
          />
        </FormGroup>

        <FormGroup>
          <label>Description:</label>
          <input
            type='text'
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            ref={descriptionInputRef}
          />
        </FormGroup>

        <FormGroup>
          <label>Debit:</label>
          <input
            type='number'
            onChange={(e) => setDebit(e.target.value)}
            value={debit}
          />
        </FormGroup>
        <ButtonContainer>
          <button onClick={toastytoast()} disabled={!isFormValid}>
            Add Sale
          </button>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ButtonContainer>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 320px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  label {
    font-weight: bold;
    margin-bottom: 5px;
    font-family: "Open Sans";
    color: #303030;
    font-size: 14px;
    font-weight: 400;
  }

  input {
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: lightgrey;
    outline: none;
    font-size: 14px;
    position: relative;
    font-family: "Open Sans";
    flex: 1;
    margin-left: 10px;
  }

  .error {
    border-color: red;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  margin-top: 10px;
  width: 360px;

  button {
    padding: 10px 16px;
    width: 160px;
    background-color: rgba(0, 100, 225, 255);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
  button:disabled {
    padding: 10px 16px;
    width: 160px;
    background-color: darkgray;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3); */
    color: white;
    cursor: not-allowed;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    margin-right: 0;
    position: relative;
    right: -160px;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 5px;
`;

const SearchResults = styled.div`
  position: absolute;
  padding: 0;
  width: 173.5px;
  background-color: lightgray;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 116px;
  right: 127px;
  z-index: 999;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;
`;

const InvoiceButton = styled.div`
  background-color: transparent;
  position: absolute;
  top: 6px;
  right: 2px;
`;

const DropDownList = styled.ul`
  list-style-type: none; // Add this line

  &:hover {
    cursor: pointer;
  }
`;

const SelectedList = styled.li`
  padding: 0 11px 4px 9px;
  background: ${(props) => (props.highlighted ? "darkgrey" : "lightrey")};
  display: flex;
  font-size: 14px;
  font-weight: 400;
  font-family: "Open Sans";
  flex: 1;
`;

export default SaleForm;
