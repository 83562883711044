// import styled from "styled-components";
// import Leftside from "../components/Leftside";
// import InvoiceForm from "../pages/Invoice/invoiceForm";
// import RightsideInvoice from "../components/RightsideInvoice";
// import { useState, useEffect } from "react";

// const InvoiceDashboard = (props) => {
//   const [runFunction, setRunFunction] = useState(false);
//   const [autorunFunction, setAutoRunFunction] = useState(false);
//   const [printrunFunction, setPrintRunFunction] = useState(false);

//   const handleButtonClick = () => {
//     setRunFunction(true);
//   };

//   const handleCheckBoxAS = (event) => {
//     if (event.target.checked) {
//       setAutoRunFunction(true);
//     } else {
//       setAutoRunFunction(false);
//     }
//   };

//   const resetRun = () => {
//     setRunFunction(false);
//   };

//   const handleDownloadPDF = () =>{
//     setPrintRunFunction(true)
//   }

//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if ((event.ctrlKey || event.metaKey) && event.key === 's') {
//         event.preventDefault();
//         handleButtonClick();
//       }
//     };
//     window.addEventListener("keydown", handleKeyDown);

//     // Cleanup
//     return () => {
//       window.removeEventListener("keydown", handleKeyDown);
//     }
//   }, []);

//   return (
//     <Container>
//       <Layout>
//         <Leftside />
//         <InvoiceForm
//           shouldAutoRunFunction={autorunFunction}
//           shouldRunFunction={runFunction}
//           resetRun={resetRun}
//           shouldPrintRunFunction={printrunFunction}
//         />
//         <RightsideInvoice
//           checked={autorunFunction}
//           handleChange={handleCheckBoxAS}
//           onButtonClick={handleButtonClick}
//           onDownloadClick={handleDownloadPDF}
//         />
//       </Layout>
//     </Container>
//   );
// };

// const Container = styled.div`
//   padding-top: 52px;
//   max-width: 100%;
//   margin-right: 10px;
//   background-color: white;
//   /* ------- */
//   position: fixed;
//   top: 0;
//   left: 0;
//   height: 100%;
//   z-index: -10;
//   display: flex;
//   /* align-items: center; */
//   justify-content: center;
//   overflow-y: auto;
// `;

// const Layout = styled.div`
//   display: grid;
//   margin-left: 10px;
//   grid-gap: 10px;
//   padding: 0 100px;
//   grid-template-areas: "leftside main rightside";
//   grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
//   column-gap: 25px;
//   row-gap: 25px;
//   grid-template-rows: auto;
//   /* grid-template-columns: auto; */
//   margin: 25px 0;
//   @media (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//     padding: 0 5px;
//   }
// `;

// export default InvoiceDashboard;

import styled from "styled-components";
import Leftside from "../components/Leftside";
import InvoiceForm from "../pages/Invoice/invoiceForm";
import RightsideInvoice from "../components/RightsideInvoice";
import { useState, useEffect } from "react";

const InvoiceDashboard = (props) => {
  const [runFunction, setRunFunction] = useState(false);
  const [autorunFunction, setAutoRunFunction] = useState(false);
  const [printrunFunction, setPrintRunFunction] = useState(false);

  const handleButtonClick = () => {
    setRunFunction(true);
  };

  const handleCheckBoxAS = (event) => {
    if (event.target.checked) {
      setAutoRunFunction(true);
    } else {
      setAutoRunFunction(false);
    }
  };

  const resetRun = () => {
    setRunFunction(false);
  };

  const handleDownloadPDF = () => {
    setPrintRunFunction(true);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === "s") {
        event.preventDefault();
        handleButtonClick();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Container>
      <Layout>
        <Leftside />
        <InvoiceForm
          shouldAutoRunFunction={autorunFunction}
          shouldRunFunction={runFunction}
          resetRun={resetRun}
          shouldPrintRunFunction={printrunFunction}
        />
        <RightsideInvoice
          checked={autorunFunction}
          handleChange={handleCheckBoxAS}
          onButtonClick={handleButtonClick}
          onDownloadClick={handleDownloadPDF}
        />
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  padding-top: 52px;
  max-width: 100%;
  margin-right: 10px;
  background-color: white;
  /* ------- */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: -10;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* overflow-y: auto; */
  @media (max-width: 1366px) {
    padding-top: 52px;
    max-width: 100%;
    margin-right: 10px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -10;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  @media (max-width: 1280px) {
    padding-top: 52px;
    max-width: 100%;
    margin-right: 10px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -10;
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
`;

const Layout = styled.div`
  display: grid;
  margin-left: 10px;
  grid-gap: 10px;
  padding: 0 100px;
  grid-template-areas: "leftside main rightside";
  grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
  column-gap: 25px;
  row-gap: 25px;
  grid-template-rows: auto;
  /* grid-template-columns: auto; */
  margin: 25px 0;
  @media (max-width: 1366px) {
    display: grid;
    margin-left: 10px;
    grid-gap: 10px;
    padding: 0 20px;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    /* grid-template-columns: auto; */
    margin: 25px 0;
  }

  @media (max-width: 1280px) {
    display: grid;
    margin-left: 10px;
    grid-gap: 10px;
    padding: 0 20px;
    grid-template-areas: "leftside main rightside";
    grid-template-columns: minmax(0, 5fr) minmax(0, 12fr) minmax(230px, 7fr);
    column-gap: 25px;
    row-gap: 25px;
    grid-template-rows: auto;
    /* grid-template-columns: auto; */
    margin: 25px 0;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
`;

export default InvoiceDashboard;
