import { RequestsContext } from "../context/requestContext";
import { useContext } from "react";

export const useRequestsContext = () => {
  const context = useContext(RequestsContext);
  if (!context) {
    throw Error(
      "useRequestContext must be used inside an RequestsContextProvider"
    );
  }
  return context;
};